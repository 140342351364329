import React, { useEffect, useState } from "react";
// import Progress from "../../../progress/Progress";

// import { browserUserData } from '../../components/charts/analytics/AnalyticsData'

// import { browserUserData, browserUserDataSet2, browserUserDataSet3 } from "../../charts/analytics/AnalyticsData";
import {  DropdownItem, UncontrolledDropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { topCampList } from "../../app/api";
// import { DataTableRow, DataTableHead, DataTableItem } from "../../../table/DataTable";
import { DataTableRow, DataTableHead, DataTableItem, Icon, Progress } from "../../components/Component";


const CampData = ({campData, totalAd}) => {


  // const browserUserData = (campData) ? campData : [];

  // const [cdata, setCdata] = useState(browserUserData);

// Function to initialize the session expiration timer
function initializeSessionTimeout() {
  // Set the timeout duration to 10 minutes (600,000 milliseconds)
  const timeoutDuration = 600000;
  setTimeout(function() {
    // Clear session storage when the timeout is reached
    sessionStorage.clear();
    localStorage.removeItem("accessToken");
  }, timeoutDuration);
}
initializeSessionTimeout();


  return (
    <React.Fragment>
      <div className="card-inner mb-n2">
        <div className="card-title-group">
          <div className="card-title card-title-sm">
            <h5 className="title">Your Ad Units</h5>
            <p className="title">You have total {totalAd} ad units</p>
          </div>
        </div>
      </div>
      
      <div className="traffic-channel-group g-2">
      {campData ? campData.map((item, key) => {
        let col = [
          "#798bff", "#b8acff", '#1ee0ac', '#fe8eaa' , '#be74ff', '#f8921e'
        ];

        return (
          <div className="traffic-channel-data">
            <div className="title pl-2">
              <span className="dot dot-lg sq" style={{ background: col[key] }}></span>
              <span className="text-capitalize">{(item.ad_type.toLowerCase() == 'social') ? 'In-Page Push': item.ad_type} Ad</span>
            </div>
            <div className="amount pl-2">
              <small>{item.ads}</small>
            </div>
          </div>)
      }) : 
      <>
        <div className="traffic-channel-data">
            <div className="title">
              <span className="dot dot-lg sq" style={{ background: '#798bff' }}></span>
              <span>Text Ad</span>
            </div>
            <div className="amount">
              <small>0</small>
            </div>
          </div>
          <div className="traffic-channel-data">
            <div className="title">
              <span className="dot dot-lg sq" style={{ background: '#b8acff' }}></span>
              <span>Banner Ad</span>
            </div>
            <div className="amount">
              <small>0</small>
            </div>
          </div>
          <div className="traffic-channel-data">
            <div className="title">
              <span className="dot dot-lg sq" style={{ background: '#1ee0ac' }}></span>
              <span>Native Ad</span>
            </div>
            <div className="amount">
              <small>0</small>
            </div>
          </div>
          <div className="traffic-channel-data">
            <div className="title">
              <span className="dot dot-lg sq" style={{ background: '#fe8eaa' }}></span>
              <span>Socialbar Ad</span>
            </div>
            <div className="amount">
              <small>0</small>
            </div>
          </div>
          <div className="traffic-channel-data">
            <div className="title">
              <span className="dot dot-lg sq" style={{ background: '#be74ff' }}></span>
              <span>Popunder Ad</span>
            </div>
            <div className="amount">
              <small>0</small>
            </div>
          </div>
      </> }
          {/* <div className="traffic-channel-data">
            <div className="title">
              <span className="dot dot-lg sq" style={{ background: "#b8acff" }}></span>
              <span>Pending Website</span>
            </div>
            <div className="amount">
              <small>23</small>
            </div>
          </div> */}
        </div>
    
    </React.Fragment>
  );
};
export default CampData;
