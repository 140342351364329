import React, {useState, useEffect} from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { Sendrequest, onMessageListener } from './firebase';
import { useContext } from 'react';
import AppContext from './context/AppContext';
import { getNotificationShort } from './app/api';


const Notification = () => {
    const [notification, setNotification] = useState({title: '', body: ''});
    const apc = useContext(AppContext);

    const notify = () =>  toast(<ToastDisplay/>);
  
    function ToastDisplay() {
        return (
            <div>
            <p><b>{notification?.title}</b></p>
            <p>{notification?.body}</p>
            </div>
        );
    }

    const loadNotificationList = async () => {
        let res = await getNotificationShort();
        if(res.data) {
            apc.dispatch({
                type:"noti",
                data:res.data,
                row:res.count
            });
        }
    }

    useEffect(() => {
        if (notification?.title) {  
            notify()
        }
        Sendrequest();
        
        onMessageListener()
            .then((payload) => {
            setNotification({title: payload?.notification?.title, body: payload?.notification?.body});     
            loadNotificationList();
        })
            .catch((err) => console.log('failed: ', err));
    }, [notification]);

    return (
        <Toaster/>
    )
}

export default Notification