import React, { useState, useContext, useEffect, useRef } from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import Logo from "../logo/Logo";
import News from "../news/News";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";
import AppContext from "../../context/AppContext";
import { Link } from "react-router-dom";
import {
  Alert,
  Badge,
  Popover,
  PopoverBody,
  PopoverHeader,
  Spinner,
  Modal,
  Form,
  Button,
} from "reactstrap";
import { Icon, TooltipComponent } from "../../components/Component";
import { userBalanceData, postFeedbackReq, sliderContentHeader } from "../../app/api";
import { toast } from "react-toastify";

const Header = ({ fixed, theme, className, setVisibility, ...props }) => {
  const apc = useContext(AppContext);

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme === "white",
    [`is-${theme}`]: theme !== "white" && theme !== "light",
    [`${className}`]: className,
  });

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const [subject, setSubject] = useState("Tell us about your experience.");
  const [msg, setMsg] = useState("");
  const [composeModal, setComposeModal] = useState(false);
  const toggleModal = () => {
    setComposeModal(!composeModal);
  };
  const [errors, setErrors] = useState({
    subject: "",
    message: "",
  });
  const [save, setSave] = useState(false);
  const [attachmentList, setAttachmentList] = useState(null);
  const [rating, setRating] = useState(5);
  const [hover, setHover] = useState(null);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle2 = () => setPopoverOpen(!popoverOpen);

  const [ernAmt, setEarnAmt] = useState(0);
  const [widAmt, setWidAmt] = useState(0);
  const [avlAmt, setAvlAmt] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sliderData, setSliderContent] = useState(null);
  const marqueeRef = useRef(null);
  const support_pin = localStorage.getItem('support_pin');

  const getWallletData = async () => {
    setLoading(true);
    let uid = localStorage.getItem("uid");
    let res = await userBalanceData({
      uid: uid,
    });

    if (res.code == 200) {
      setEarnAmt(res.total_earning);
      setWidAmt(res.total_withdrawl);
      setAvlAmt(res.avalable_amt);
    }
    setLoading(false);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    let err = await validateData();
    if(err.subject !== "" || err.message !== "") {
      setErrors(err);
      return false;
    }
    if(save) {
      return;
    }
    setSave(true);
    let uid = localStorage.getItem("uid");
    let form = new FormData();
    let ferr = 0;

    if (attachmentList !== null) {
      let ext = attachmentList.type
        ? attachmentList.type.split("/")[1].toLowerCase()
        : "";
      let size = Math.round(attachmentList.size / 1024);
      // console.log(ext);
      if (ext == "jpeg" || ext == "jpg" || ext == "png" || ext == "pdf") {
        if (size <= 500) {
          form.append("attachment", attachmentList, attachmentList.name);
          ferr = 0;
        } else {
          ferr = 1;
          toast.error(
            "Image file size is too large. Maximum file size is 500kb",
            {
              position: "top-right",
              autoClose: true,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            }
          );
          setSave(false);
        }
      } else {
        ferr = 1;
        toast.error("You can upload jpg, png or pdf file only.", {
          position: "top-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
        setSave(false);
      }
    }

    form.append("user_id", uid);
    form.append("subject", subject.trim());
    form.append("message", msg.trim());
    form.append("rating", rating);
    form.append("type", "2");

    if (ferr == 0) {
      let res = await postFeedbackReq(form);
      if (res.code == 200) {
        toggleModal();
        toast.success("Feedback Sent!", {
          position: "top-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      }
      // loadData();
      setSave(false);
      setErrors({});
      setSubject("Tell us about your experience.");
      setMsg("");
      setRating(5);
      setAttachmentList(null);
    }
  };

  const remainingCharactersSub = 50 - subject.length;
  const handleSubjectChange = (e) => {
    const value = e.target.value;
    setSubject(value);
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, subject: "" }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, subject: "Subject field is required." }));
    }
  };

  const remainingCharactersMsg = 300 - msg.length;
  const handleMessageChange = (e) => {
    const value = e.target.value;
    setMsg(value);
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, message: "" }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, message: "Message field is required." }));
    }
  };

  const validateData = () => {
    var err = { subject: "", message: "" };
    if (msg.length === 0 || msg.trim() === "") {
      err["message"] = "Message field is required.";
    }
    if (subject.length === 0 || subject.trim() === "") {
      err["subject"] = "Subject field is required.";
    }
    if (subject.length > 50) {
      err["subject"] = "Subject must be at maximum 50 characters long.";
    }
    return err;
  };

  const onAttachmentClick = () => {
    const inputEl = document.getElementById("attachmentInput");
    inputEl.click();
    inputEl.onchange = onAttachmentChange;
  };

  const onAttachmentChange = (ev) => {
    if (ev.target.files.length > 0) {
      setAttachmentList(ev.target.files[0]);
    }
  };

  const outsideClickListener = (event) => {
    let elm =
      document.getElementById("popb") != null
        ? document.getElementById("popb")
        : "";
    if (elm) {
      if (!elm.contains(event.target) && popoverOpen) {
        setPopoverOpen(false);
      }
    }
  };

  
  const sliderContent = async() => {
    let uid = localStorage.getItem("uid");
    let res = await sliderContentHeader(uid);
    if (res.code == 200) {
      setSliderContent(res.data);
    }
  };

  useEffect(() => {
    sliderContent();
    document.addEventListener("click", outsideClickListener);
  }, []);

  useEffect(() => {
    const marqueeElement = marqueeRef.current;
    const contentElement = marqueeElement?.firstChild;

    if (contentElement) {
      const stopMarquee = () => marqueeElement.stop();
      const startMarquee = () => marqueeElement.start();

      contentElement.addEventListener('mouseover', stopMarquee);
      contentElement.addEventListener('mouseout', startMarquee);

      return () => {
        contentElement.removeEventListener('mouseover', stopMarquee);
        contentElement.removeEventListener('mouseout', startMarquee);
      };
    }
  }, [sliderData]);
  
  return (
    <>
      <div className={headerClass}>
        <div className="container-fluid">
          <div className="nk-header-wrap">
            <div className="nk-menu-trigger d-xl-none ml-n1">
              <Toggle
                className="nk-nav-toggle nk-quick-nav-icon d-xl-none ml-n1"
                icon="menu"
                click={props.sidebarToggle}
              />
            </div>
            <div className="nk-header-brand d-xl-none">
              <Logo />
            </div>

            {sliderData !== null && apc.sts.perms.includes('9999') && 
            <div className="nk-header-news d-none d-xl-block mr-2 text-center slider-header-wrap-custom" style={{width:"12%"}}
              dangerouslySetInnerHTML={{ __html: sliderData != null && sliderData.header_content }}
            />
            }

            {sliderData !== null && apc.sts.perms.includes('9999') &&
            <div className="nk-header-news d-none d-xl-block" style={{width:"49%"}}>
              <marquee 
                scrollamount={sliderData != null ? sliderData.content_speed : '12'} 
                direction="left"    
                ref={marqueeRef}
                dangerouslySetInnerHTML={{ __html: sliderData != null && sliderData.slider_content }}
              ></marquee>
            </div>
            }
            
            <div className="nk-header-tools">
              <ul className="nk-quick-nav">
                {/* <Badge color="danger" pill>Low Balance</Badge> */}
                {apc.sts.perms.includes('413') || apc.sts.perms.includes('9999') ?
                <li style={{ width: "130px"}}>
                  <Link
                    className="btn btn-primary btn-sm mwbtn"
                    to={`${process.env.PUBLIC_URL}/websites`}
                  >
                    Manage Websites
                  </Link>
                </li>
                :''}
                {apc.sts.perms.includes('9999') ?
                <li>
                  <a
                    className="btn feedback-btn btn-sm"
                    href="#toggle"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setComposeModal(true);
                    }}
                  >
                    <span>Feedback</span>
                  </a>
                </li> : ''}
                {support_pin != null && apc.sts.perms.includes('9999') ?
                <li style={{width:'180px'}}>
                  <b style={{background:'#f4f9ff',color:'#3b82f6',padding:'7px',borderRadius:'3px',fontSize:'13px'}}>
                    Support Pin: {support_pin}
                  </b>
                </li>
                : ''}
                {/* {console.log("test: ", apc.sts.wlt)} */}
                {apc.sts.perms.includes('433') || apc.sts.perms.includes('9999') ?
                <li className="balleft">
                  <a href="javascript:;" id="Popover1" onClick={getWallletData}>
                    Balance
                    <br />
                    <b className="text-primary fs-18 hdfont">
                      $
                      {typeof apc.sts.wlt !== "undefined" ? (
                        apc.sts.wlt
                      ) : (
                        <Spinner size={"sm"} className="ml-1" />
                      )}
                    </b>
                  </a>
                </li> : ''}
                {apc.sts.perms.includes('433') || apc.sts.perms.includes('9999') ?
                <Popover
                  id="popb"
                  isOpen={popoverOpen}
                  placement="bottom"
                  target="Popover1"
                  toggle={toggle2}
                >
                  <PopoverHeader>Balance Detail</PopoverHeader>
                  <PopoverBody>
                    <ul className="amt-pop-ul">
                      <li className="text-info mb-1">
                        Total Earning{" "}
                        <span className="float-right">
                          {loading ? <Spinner size="sm" /> : "$" + ernAmt}
                        </span>
                      </li>
                      <li className="text-success mb-1">
                        Total Withdrawal{" "}
                        <span className="float-right">
                          {loading ? <Spinner size="sm" /> : "$" + widAmt}
                        </span>
                      </li>
                      <li className="text-danger">
                        Balance Amount{" "}
                        <span className="float-right">
                          {loading ? <Spinner size="sm" /> : "$" + avlAmt}
                        </span>
                      </li>
                    </ul>
                  </PopoverBody>
                </Popover>
                : '' }
                {apc.sts.perms.includes('440') || apc.sts.perms.includes('9999') ?
                <li
                  className="notification-dropdown mr-n1"
                  onClick={() => {
                    // setVisibility(false)
                  }}
                >
                  <Notification
                    toggle={toggle}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                  />
                </li> : ''}
                <li
                  className="user-dropdown"
                  onClick={() => setVisibility(false)}
                >
                  <User />
                </li>
              </ul>
            </div>
            <Modal
              isOpen={composeModal}
              className="modal-lg"
              toggle={() => toggleModal()}
            >
              <div className="modal-header">
                <h6 className="modal-title">Provide Your Feedback</h6>
                <a
                  href="#cancel"
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleModal();
                    setSubject("Tell us about your experience.");
                    setMsg("");
                    setRating(5);
                    setErrors({});
                    setAttachmentList(null);
                  }}
                  className="close"
                >
                  <Icon name="cross-sm"></Icon>
                </a>
              </div>
              <Form onSubmit={submitForm}>
                <div className="modal-body p-4">
                  <div className="nk-reply-form-editor pl-4 pr-4 pb-4">
                    <div className=" mb-2">
                      <div className="mt-1">
                        <p className="feedback-rating-stars">
                          <b>Please Rate Us</b>
                        </p>
                        {[...Array(5)].map((star, i) => {
                          const ratingValue = i + 1;
                          return (
                            <label style={{ marginBottom: "0px", marginLeft: "-3px"  }} key={i}>
                              <input
                                style={{ display: "none" }}
                                type="radio"
                                name="rating"
                                value={ratingValue}
                                onClick={() => setRating(ratingValue)}
                              />
                              <Icon
                                name="star"
                                style={{
                                  color:
                                    ratingValue <= (hover || rating)
                                      ? "#fdc716"
                                      : "#ccc",
                                  cursor: "pointer",
                                  fontSize: "22px",
                                }}
                                className="ni-star-fill"
                                onMouseEnter={() => setHover(ratingValue)}
                                onMouseLeave={() => setHover(null)}
                              />
                            </label>
                          );
                        })}
                      </div>
                    </div>

                    <div className="nk-reply-form-editor">
                      <div className="nk-reply-form-field custom-border">
                        <input
                          type="text"
                          className="form-control form-control-simple h-25"
                          placeholder="Subject"
                          value={subject}
                          name="subject"
                          maxLength={50}
                          onChange={handleSubjectChange}
                        />
                      </div>
                      {errors.subject && (<span className="text-danger">{errors.subject}</span>)}
                      <div style={{color:"#8094ae"}}>Remaining Characters : {remainingCharactersSub}</div>

                      <div className="nk-reply-form-field custom-border mt-2">
                        <textarea
                          className="form-control form-control-simple no-resize ex-large"
                          placeholder="Write your feedback about our services."
                          value={msg}
                          name="message"
                          maxLength={300}
                          onChange={handleMessageChange}
                        ></textarea>
                      </div>
                      {errors.message && (<span className="text-danger">{errors.message}</span>)}
                      <div style={{color:"#8094ae"}}>Remaining Characters : {remainingCharactersMsg}</div>
                    </div>

                    <div className="">
                      <div className="nk-reply-form-attachment mt-4">
                        {attachmentList != null ? (
                          <div className="nk-reply-form-attachment-list p-1 align-center justify-between d-flex">
                            <span>{attachmentList.name}</span>
                            <a
                              className="toggle-opt"
                              href="remove"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setAttachmentList(null);
                                document.querySelector("#attachmentInput").value = '';
                              }}
                            >
                              <Icon name="cross"></Icon>
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className=" mt-2">
                        <ul className="nk-reply-form-actions g-1">
                          <li onClick={() => onAttachmentClick()}>
                            <TooltipComponent
                              title="Attachment"
                              tag="a"
                              containerClassName="btn btn-icon"
                              icon="clip-v"
                              id="ibx-msg-attachment"
                              direction="top"
                              text="Upload Attachment"
                            />
                            <input
                              type="file"
                              id="attachmentInput"
                              accept="image/*, application/pdf"
                              style={{ display: "none" }}
                            ></input>
                          </li>
                          <li className="ml-2">
                            <Button color="primary" type="submit">
                              {save ? (
                                <span>
                                  <Spinner size="sm" /> &nbsp;Processing..
                                </span>
                              ) : (
                                <span> Submit Feedback </span>
                              )}
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Modal>
          </div>
        </div>
      </div>
      {/* <div className="row p-4 mt-3">
        <div className="col-md-12">
        <Alert className="alert-icon mt-5" color="danger">
          <Icon name="alert-circle" />
          Your <strong>wallet balance</strong> is low. Please &nbsp; 
          <Link to="payment" className="alert-link">
            Add Fund
          </Link>{" "} 
          in your wallet.
        </Alert>
        </div>
      </div> */}
    </>
  );
};
export default Header;