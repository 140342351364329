import React, { useState } from "react";
import { CountryDoughnut, SessionDoughnut } from "../../charts/analytics/AnalyticsCharts";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { Icon } from "../../../Component";

const ClickCountry = ({dvData}) => {
  const [sessionDevice, setSessionDevices] = useState("30");

  return (
    <React.Fragment>
      <div className="card-title-group">
        <div className="card-title card-title-sm">
          <h6 className="title">Countries-wise Clicks</h6>
        </div>
      </div>
      <div className="device-status my-auto">
        <div className="device-status-ck">
          {dvData ? 
          <CountryDoughnut className="analytics-doughnut" state={dvData} />
          : ''}
        </div>
      </div>
    </React.Fragment>
  );
};
export default ClickCountry;
