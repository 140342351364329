import React, { useEffect, useState } from "react";
import { contacts, inboxList } from "./InboxData";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Modal,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import { Icon, RSelect, TooltipComponent } from "../../components/Component";
import { postSupportReq } from "../../app/api";
import { toast, ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";

const InboxBodyHead = ({
  allData,
  setData,
  currentTab,
  toggleRefresh,
  itemPerPage,
  totalItems,
  paginate,
  currentPage,
  loadData,
}) => {
  const [composeModal, setComposeModal] = useState(false);
  const [opt, setOpt] = useState("");
  const [opt2, setOpt2] = useState("");
  const [subject, setSubject] = useState("");
  const [msg, setMsg] = useState("");
  const [errors, setErrors] = useState({
    subject: "",
    message: "",
  });

  const [file, setFile] = useState(null);

  const toggleModal = () => {
    setComposeModal(!composeModal);
  };

  const data = [
    { value: "complaint", label: "Complaint" },
    { value: "feedback", label: "Feedback" },
  ];

  const data2 = [
    { value: "payout related", label: "Payment Issue" },
    { value: "website or ad unit", label: "Website or Ad Unit" },
    { value: "other", label: "Other" },
  ];

  useEffect(() => {
    setData(inboxList);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
    pageNumbers.push(i);
  }

  const nextPage = () => {
    let pgs = currentPage + 1;
    paginate(pgs);
    loadData(pgs);
  };

  const prevPage = () => {
    let pgs = currentPage - 1;
    paginate(pgs);
    loadData(pgs);
  };

  const [save, setSave] = useState(false);

  const validateData = () => {
    var err = { subject: "", message: "", select: "" };
    if (msg.length === 0 || msg.trim() === "") {
      err["message"] = "Message field is required";
    }
    if (subject.length === 0 || subject.trim() === "") {
      err["subject"] = "Subject field is required";
    }
    if (subject.length > 100) {
      err["subject"] = "Subject must be at maximum 50 characters long.";
    }
    if (opt == "") {
      err["select"] = "Please select request type.";
    }
    if (opt == "complaint" && opt2 == "") {
      err["select"] = "Please select request Subtype.";
    }
    return err;
  };

  const submitForm = async (e) => {
    e.preventDefault();

    let err = await validateData();

    if (err.subject !== "" || err.message !== "" || err.select !== "") {
      setErrors(err);
      return false;
    }
    if (save) {  // not click for multiple
      return;
    }
    setSave(true);

    let uid = localStorage.getItem("uid");
    let form = new FormData();
    let ferr = 0;

    if (attachmentList !== null) {
      let ext = attachmentList.type ? attachmentList.type.split("/")[1].toLowerCase() : "";
      let size = Math.round(attachmentList.size / 1024);
      let mimeType = attachmentList.type;

      const allowedExtensions = ["jpeg", "jpg", "png", "pdf", "txt"];
      const allowedMimeTypes = [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/jpeg",
        "image/png",
        "application/pdf",
        "text/plain"
      ];
      // if (ext == "jpeg" || ext == "jpg" || ext == "png" || ext == "pdf") {
      if (allowedExtensions.includes(ext) || allowedMimeTypes.includes(mimeType)) {
        if (size <= 500) {
          form.append("file", attachmentList, attachmentList.name);
          ferr = 0;
        } else {
          ferr = 1;
          toast.error("Image file size is too large. Maximum file size is 500kb", {
            position: "top-right",
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
          setSave(false);
        }
      } else {
        ferr = 1;
        toast.error("You can upload jpg, png, pdf, txt, or docx files only.", {
          position: "top-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
        setSave(false);
      }
    }

    form.append("uid", uid);
    form.append("category", opt);
    form.append("sub_category", opt2);
    form.append("subject", subject.trim());
    form.append("message", msg.trim());
    form.append("support_type", "Publisher");

    if (ferr == 0) {
      let res = await postSupportReq(form);
      if (res.code == 200) {
        toggleModal();
        toast.success("Request Created!", {
          position: "top-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      }
      loadData();
      setSave(false);
      setErrors({});
      setSubject("");
      setMsg("");
      setOpt("");
      setOpt2("");
      setAttachmentList(null);
    }
  };

  const [attachmentList, setAttachmentList] = useState(null);

  const onAttachmentClick = () => {
    const inputEl = document.getElementById("attachmentInput");
    inputEl.click();
    inputEl.onchange = onAttachmentChange;
  };

  const onAttachmentChange = (ev) => {
    if (ev.target.files.length > 0) {
      setAttachmentList(ev.target.files[0]);
      // setAttachmentList(ev.target.files[0]);
    }
  };

  const remainingCharactersSub = 100 - subject.length;
  const handleSubjectChange = (e) => {
    const value = e.target.value;
    setSubject(value);
  };

  const remainingCharactersMsg = 2000 - msg.length;
  const handleMessageChange = (e) => {
    const value = e.target.value;
    setMsg(value);
  };

  // const removeAttachment = () => {
  //   setAttachmentList(null);
  // };

  return (
    <>
      <div className="nk-ibx-head icnhed">
        <div className="nk-ibx-head-actions">
          <ul className="nk-ibx-head-tools g-1">
            <li>
              <h5 className="mb-0">
                <Icon name="account-setting-fill" style={{ fontSize: 22 }} />{" "}
                Support Center
              </h5>
            </li>
          </ul>
        </div>
        <div>
          <ul className="nk-ibx-head-tools g-1">
            <li style={{ borderRight: "1px solid #ddd" }}>
              <a
                href="#toggle"
                onClick={(ev) => {
                  ev.preventDefault();
                  setComposeModal(true);
                }}
                className="btn btn-primary newreq"
              >
                <Icon name="plus"></Icon>
                <span>New Request</span>
              </a>
            </li>
            <li>
              <a
                href="#item"
                onClick={(ev) => {
                  ev.preventDefault();
                  loadData(1);
                  paginate(1);
                }}
                className="btn btn-icon btn-trigger"
              >
                <Icon name="undo"></Icon>
              </a>
            </li>
            <li>
              <a
                href="#item"
                onClick={(ev) => {
                  ev.preventDefault();
                  prevPage();
                }}
                className={`btn btn-trigger btn-icon btn-tooltip ${
                  currentPage - 1 === 0 ? "disabled" : ""
                }`}
              >
                <Icon name="arrow-left"></Icon>
              </a>
            </li>
            <li>
              <a
                href="#item"
                onClick={(ev) => {
                  ev.preventDefault();
                  nextPage();
                }}
                className={`btn btn-trigger btn-icon btn-tooltip ${
                  pageNumbers[pageNumbers.length - 1] === currentPage ||
                  pageNumbers.length === 0
                    ? "disabled"
                    : ""
                }`}
              >
                <Icon name="arrow-right"></Icon>
              </a>
            </li>
          </ul>
        </div>
        <Modal
          isOpen={composeModal}
          className="modal-lg"
          toggle={() => toggleModal()}
          backdrop="static"
          keyboard={false}
        >
          <div className="modal-header">
            <h6 className="modal-title">Create New Request</h6>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                toggleModal();
                setErrors({});
                setSubject("");
                setMsg("");
                setOpt("");
                setOpt2("");
                setAttachmentList(null);
              }}
              className="close"
            >
              <Icon name="cross-sm"></Icon>
            </a>
          </div>
          <Form onSubmit={submitForm}>
            <div className="modal-body p-4">
              <div className="pl-4 pr-4">
                <div className="nk-reply-form-header">
                  <div className="nk-reply-form-group">
                    <div className="nk-reply-form-title d-sm-none mb-2">
                      Reply
                    </div>
                    {/* <div className="nk-reply-form-input-group">
                      <div className="row">
                        <div className="col-md-6 pl-0 pr-0">
                          <RSelect
                            options={data}
                            onChange={(ev) => {
                              setOpt(ev.value);
                            }}
                          />
                        </div>
                        <div className="col-md-6 compinbos pr-0">
                          {opt == "complaint" && (
                            <RSelect
                              options={data2}
                              onChange={(ev) => {
                                setOpt2(ev.value);
                              }}
                            />
                          )}
                        </div>
                        {opt != "complaint" && errors.select && <span className="text-danger">{errors.select}</span>}
                      </div>
                      {opt == "complaint" && opt2 == "" && errors.select && (
                        <span className="text-danger" style={{ marginLeft: "330px" }}>
                          {errors.select}
                        </span>
                      )}
                    </div> */}
                    <div className="nk-reply-form-input-group">
                      <div className="row">
                        <div className="col-md-6 pl-0 pr-0">
                          <RSelect
                            options={data}
                            onChange={(ev) => {
                              setOpt(ev.value);
                              setOpt2(""); // Reset opt2 when opt changes
                            }}
                          />
                          {/* Display error if no option is selected in the first dropdown */}
                          {opt === "" && errors.select && (
                            <span className="text-danger">{errors.select}</span>
                          )}
                        </div>
                        <div className="col-md-6 compinbos pr-0">
                          {opt === "complaint" && (
                            <>
                              <RSelect
                                options={data2}
                                onChange={(ev) => {
                                  setOpt2(ev.value);
                                }}
                              />
                              {/* Display error if "complaint" is selected but no option is selected in the second dropdown */}
                              {opt2 === "" && errors.select && (
                                <span className="text-danger">
                                  {errors.select}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="nk-reply-form-editor">
                  <div className="mb-2">
                    <div className="nk-reply-form-field custom-border">
                      <input
                        type="text"
                        className="form-control form-control-simple h-25"
                        placeholder="Subject"
                        value={subject}
                        name="subject"
                        maxLength={100}
                        // onChange={(e) => {setSubject(e.target.value);}}
                        onChange={handleSubjectChange}
                      />
                    </div>
                    {subject.trim() == "" && errors.subject && (<span className="text-danger">{errors.subject}</span>)}
                    <div>Remaining Characters : {remainingCharactersSub}</div>
                  </div>

                  <div className="nk-reply-form-field custom-border">
                    <textarea
                      className="form-control form-control-simple no-resize ex-large"
                      placeholder="Your Message"
                      value={msg}
                      name="message"
                      // onChange={(e) => setMsg(e.target.value)}
                      maxLength={2000}
                      onChange={handleMessageChange}
                    ></textarea>
                  </div>
                  {msg.trim() == "" && errors.message && (<span className="text-danger">{errors.message}</span>)}
                  <div>Remaining Characters : {remainingCharactersMsg}</div>

                  <div className="nk-reply-form-attachment">
                    {attachmentList != null ? (
                      <div className="nk-reply-form-attachment-list p-1 align-center justify-between mt-2 d-flex">
                        <span>{attachmentList.name}</span>
                        <a
                          className="toggle-opt"
                          href="remove"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setAttachmentList(null);
                          }}
                        >
                          <Icon name="cross"></Icon>
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="mt-3 mb-2">
                  <ul className="nk-reply-form-actions g-1">
                    <li onClick={() => onAttachmentClick()}>
                      <TooltipComponent
                        title="Attachment"
                        tag="a"
                        containerClassName="btn btn-icon btn-sm"
                        icon="clip-v"
                        id="ibx-msg-attachment"
                        direction="top"
                        text="Upload Attachment"
                      />
                      <input
                        type="file"
                        id="attachmentInput"
                        accept="image/*, application/pdf"
                        style={{ display: "none" }}
                      ></input>
                    </li>
                    {/* <li>
                      <Button
                        color="primary"
                        type="submit"
                        // disabled={save}
                      >
                        {save ? (
                          <span>
                            <Spinner size="sm" /> &nbsp;Processing..
                          </span>
                        ) : (
                          <span> Send </span>
                        )}
                      </Button>
                    </li> */}
                    <li className="mr-2">
                      <Button
                        color="primary"
                        type="submit"
                        // disabled={save}
                      >
                        {save ? (
                          <span>
                            <Spinner size="sm" /> &nbsp;Processing..
                          </span>
                        ) : (
                          <span> Send </span>
                        )}
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Form>
        </Modal>
      </div>
    </>
  );
};

export default InboxBodyHead;
