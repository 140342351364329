import React, { useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo/logo.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Spinner, FormGroup } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Lottie from "lottie-react";
import anim from "../../assets/animat.json";
import { regAsPub } from "../../app/api";

const Register = ({ history }) => {
  const [passState, setPassState] = useState(false);
  const [loading, setLoading] = useState(false);
  const { errors, register, handleSubmit } = useForm();

  const handleFormSubmit = async () => {
    setLoading(true);
    let res = await regAsPub();

    if (res.token) {
      let str = window.btoa(res.token + "|" + res.email);
      // window.open(
      //   "https://advertiser.7searchppc.in/auto-auth/" + str
      // );
      // window.open(
      //   "http://localhost:3000/auto-auth/" + str + "/advertiser"
      // );
      window.location.href = "https://advertiser.7searchppc.com/auto-auth/" + str + "/advertiser"
      
    }
    // setTimeout(() => history.push(`${process.env.PUBLIC_URL}/auth-success`), 2000);
  };
  return (
    <React.Fragment>
      <Head title="Register" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xl">
          <PreviewCard className="card-bordered-new" bodyClass="card-inner-lg">
            <div className="row">
              <div className="col-lg-12">
                <div className="brand-logo pb-5 text-center">
                  <a
                    href="https://www.7searchppc.com"
                    target={"_blank"}
                    className="logo-link"
                  >
                    <img
                      className="logo-dark logo-img logo-img-lg"
                      src={LogoDark}
                      alt="logo-dark"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-6">
                <BlockContent>
                  <BlockTitle tag="h4" className="text-left">
                    Open Opportunities As <span className="hdcolor">7Search PPC Advertiser</span>
                  </BlockTitle>

                  <BlockTitle tag="h5" className="hdcolor text-left mt-3">
                    Key Benefits{" "}
                  </BlockTitle>
                  <BlockDes>
                    <ul className="listcont mb-2">
                      <li>Premium traffic with flawless targeting</li>
                      <li>Top-performing ad formats for maximum conversions</li>
                      <li>Rigorous scanning and verification of traffic</li>
                      <li>
                        Competitive bids and cutting-edge bidding strategies
                      </li>
                    </ul>
                  </BlockDes>
                  <Button
                    type="submit"
                    color="primary"
                    size="lg"
                    onClick={() => {
                      handleFormSubmit();
                    }}
                    className="btn-block mt-3 mb-3"
                  >
                    {loading ? (
                      <Spinner size="sm" color="light" />
                    ) : (
                      "Proceed to Become Advertiser"
                    )}
                  </Button>
                  <BlockDes>
                  <p>
                      <i class="fas fa-hand-point-right"></i> By clicking Proceed, you agree to share your <span className="hdcolor">“Advertiser”</span>
                      details with our <span className="hdcolor">“Publisher”</span> platform!{" "}
                    </p>
                  </BlockDes>
                </BlockContent>
              </div>
              <div className="col-lg-6">
                <Lottie animationData={anim} style={{ width: "100%" }} loop />
              </div>
            </div>
          </PreviewCard>
        </Block> 
        <footer>
          <div className="container-fluid">
            <div className="row p-3">
              <div className="col-md-6 mb-2">
                <ul className="d-flex justify-content-center">
                  <li className="footer-item mr-2 mr-md-4">
                    <a href="https://www.7searchppc.com/faqs" target="_blank">
                      FAQ
                    </a>
                  </li>
                  <li className="footer-item mr-2 mr-md-4">
                    <a href="https://www.7searchppc.com/about" target="_blank">
                      About
                    </a>
                  </li>
                  <li className="footer-item mr-2 mr-md-4">
                    <a
                      href="https://www.7searchppc.com/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li className="footer-item mr-2 mr-md-4">
                    <a
                      href="https://www.7searchppc.com/terms-conditions"
                      target="_blank"
                    >
                      Terms & Conditions
                    </a>
                  </li>
                  <li className="footer-item mr-2 mr-md-4">
                    <a href="https://www.7searchppc.com/blog" target="_blank">
                      Blog
                    </a>
                  </li>
                  <li className="footer-item mr-2 mr-md-4">
                    <a
                      href="https://www.7searchppc.com/contact"
                      target="_blank"
                    >
                      Contact us
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 text-center text-md-right mb-2">
                <div className="social-footer">
                  <a
                    target="_blank"
                    href="https://www.facebook.com/7searchPPCads"
                  >
                    <i
                      className="icon ni ni-facebook-fill"
                      style={{ fontSize: "26px" }}
                    ></i>
                  </a>
                  <a target="_blank" href="https://x.com/7searchppc_ads">
                    <i
                      className="icon ni ni-twitter-round"
                      style={{ fontSize: "26px" }}
                    ></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/7searchppc_ads/"
                  >
                    <i
                      className="icon ni ni-instagram-round"
                      style={{ fontSize: "26px" }}
                    ></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/7searchppc/"
                  >
                    <i
                      className="icon ni ni-linkedin-round"
                      style={{ fontSize: "26px" }}
                    ></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.pinterest.com/7search_ppc_ads/"
                  >
                    <i
                      className="icon ni ni-pinterest-round"
                      style={{ fontSize: "26px" }}
                    ></i>
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/@7search_ppc"
                  >
                    <i
                      className="icon ni ni-youtube-round"
                      style={{ fontSize: "26px" }}
                    ></i>
                  </a>
                  <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>
              </div>
              <div className="nk-footer-copyright-login col-md-3 mb-2 order-md-first">
              <p>&copy; {new Date().getFullYear()} 7Search PPC<sup style={{verticalAlign:'super'}}>&reg;</sup> - All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </footer>
      </PageContainer>
    </React.Fragment>
  );
};
export default Register;
