import React, { Suspense,useContext, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { RedirectAs404 } from "../utils/Utils";
import Invest from "../pages/Invest";
import AppContext from "../context/AppContext";
import Wallet from "../pages/wallet/Wallet";
import UserProfileLayout from "../pages/profile/UserProfileLayout";
import Inbox from "../pages/inbox/Inbox";
import BlockIP from "../pages/BlockIP/BlockedIP";
// import Payment from "../pages/wallet/Payment";
// import Stripe from "../pages/wallet/Stripe";
// import Success from "../pages/wallet/Success";
// import Failed from "../pages/wallet/Failed";
import Support from "../pages/support/Support";
import Report from "../pages/Report";
// import Invoice from "../pages/wallet/Invoice";
// import BtcSuccess from "../pages/wallet/BtcSuccess";
import Websites from "../pages/website/Websites";
import AdWebsite from "../pages/website/AdWebsite";
import CreateAdUnit from "../pages/website/CreateAdUnit";
import UpdateAdUnit from "../pages/website/UpdateAdUnit";
import Welcome from "../pages/Welcome";


const Pages = () => {
  const prm = localStorage.getItem('perms')
  const ctx = useContext(AppContext);

  useLayoutEffect(() => {
    // console.log(prm);
    ctx.dispatch({
      type: "setprm",
      perms: prm 
    })

    window.scrollTo(0, 0);
  },[]);


  return (
    <Suspense fallback={<div />}>
      <Switch>
        {/*Pre-built Pages*/}
        <Route exact path={`${process.env.PUBLIC_URL}/ad-website`} component={prm.includes('414') || prm.includes('9999') ? AdWebsite : RedirectAs404}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/create-ad-unit/:wid`} component={prm.includes('418') || prm.includes('9999') ? CreateAdUnit : RedirectAs404}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/update-ad-unit/:adid`} component={prm.includes('426') || prm.includes('9999') ? UpdateAdUnit : RedirectAs404}></Route>
        {/* 
        <Route exact path={`${process.env.PUBLIC_URL}/update-campaign/:type/:cid`} component={UpdateCampaign}></Route> */}
        <Route exact path={`${process.env.PUBLIC_URL}/websites`} component={prm.includes('413') || prm.includes('414') || prm.includes('416') || prm.includes('418') || prm.includes('420') || prm.includes('424') || prm.includes('426') || prm.includes('428') || prm.includes('430') || prm.includes('9999') ? Websites : RedirectAs404}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/`} component={prm.includes('412') || prm.includes('9999') ? Invest : Welcome}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/payment`} component={prm.includes('432') || prm.includes('434') || prm.includes('436') || prm.includes('438') || prm.includes('9999') ? Wallet : RedirectAs404}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/profile`} component={prm.includes('446') || prm.includes('448') || prm.includes('450') || prm.includes('452') || prm.includes('454') || prm.includes('456') || prm.includes('9999') ? UserProfileLayout : RedirectAs404}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/inbox`} component={prm.includes('440') || prm.includes('9999') ? Inbox : RedirectAs404}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/inbox/detail`} component={prm.includes('440') || prm.includes('9999') ? Inbox : RedirectAs404}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/block-ip`} component={BlockIP}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/support`} component={(prm.includes('9999')) ? Support : RedirectAs404}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/report`} component={prm.includes('442') || prm.includes('9999') ? Report : RedirectAs404}></Route>
        {/* <Route exact path={`${process.env.PUBLIC_URL}/invoice/:tid`} component={Invoice}></Route> */}
        {/* <Route exact path={`${process.env.PUBLIC_URL}/payment/btc-success`} component={BtcSuccess}></Route> */}
        {/* <Route exact path={`${process.env.PUBLIC_URL}/payment/success/:trn`} component={Success}></Route> */}
        {/* <Route exact path={`${process.env.PUBLIC_URL}/payment/failed/:trn`} component={Failed}></Route> */}
        {/* <Route exact path={`${process.env.PUBLIC_URL}/user-profile-regular/`} component={UserProfileLayout}></Route> */}
        <Route exact path={`${process.env.PUBLIC_URL}/user-profile-notification/`} component={UserProfileLayout}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/user-profile-activity/`} component={prm.includes('452') || prm.includes('9999') ? UserProfileLayout : RedirectAs404}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/user-profile-setting/`} component={prm.includes('456') || prm.includes('9999') ? UserProfileLayout : RedirectAs404}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/user-payment-method/`} component={prm.includes('450') || prm.includes('9999') ? UserProfileLayout : RedirectAs404}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/user-kyc/`} component={prm.includes('458') || prm.includes('9999') ? UserProfileLayout : RedirectAs404}></Route>
        <Route exact path={`${process.env.PUBLIC_URL}/user-profile-log/`} component={prm.includes('454') || prm.includes('9999') ? UserProfileLayout : RedirectAs404}></Route>
        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
