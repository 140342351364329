import React, { useEffect, useState } from "react";
import Head from "../../layout/head/Head";
import { Badge, Card } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
} from "../../components/Component";

import { toast, ToastContainer } from "react-toastify";
import Loader from "../../app/Loader";
import { kycInfo, uploadKyc, getUserInfo } from "../../app/api";

const UserKYC = ({ sm, updateSm }) => {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const [file2, setFile2] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [kycdata, setKycData] = useState({
    photo_verified: 0,
    photo_id_verified: 0,
  });

  const kycInfoData = async () => {
    setLoading(true);
    let uid = localStorage.getItem("uid");

    let res = await kycInfo({
      uid: uid,
    });
    if (res.code == 200) {
      setKycData(res.data);
    }
    setLoading(false);

    // console.log(res);
  };

  const validateFile = (file) => {
    let ext = file.type.split("/")[1].toLowerCase();
    let size = Math.round(file.size / 1024);
    let uid = localStorage.getItem("uid");

    if (ext == "jpeg" || ext == "jpg" || ext == "png") {
      if (size <= 300) {
        return true;
      } else {
        toast.error(
          "Image file size is too large. Maximum file size is 300kb",
          {
            position: "top-right",
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          }
        );
      }
    } else {
      toast.error("You can upload jpg, png, and jpeg files only.", {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });
    }
  };

  const uploadImage = async () => {
    if (isUploading) return;

    setIsUploading(true); // Set uploading flag to true

    const uid = localStorage.getItem("uid");
    const uinfo = await getUserInfo(uid);

    if (uinfo.city == null || uinfo.address_line1 == null) {
      toast.error("Please update your profile first", {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });
      setFile("");
      setFile2("");
      setIsUploading(false); // Reset uploading flag
      return;
    }

    if (file || file2) {
      setLoading(true);

      const ext = file
        ? file.type.split("/")[1].toLowerCase()
        : file2.type.split("/")[1].toLowerCase();
      const size = file
        ? Math.round(file.size / 1024)
        : Math.round(file2.size / 1024);

      if (ext === "jpeg" || ext === "jpg" || ext === "png") {
        if (size <= 300) {
          const form = new FormData();
          if (file) form.append("user_photo", file, file.name);
          if (file2) form.append("user_photo_id", file2, file2.name);

          const updatedKycData = { ...kycdata };
          if (file && file2) {
            updatedKycData.photo_verified = 1;
            updatedKycData.photo_id_verified = 1;
          } else if (file) {
            updatedKycData.photo_verified = 1;
          } else if (file2) {
            updatedKycData.photo_id_verified = 1;
          }
          setFile("");
          setFile2("");
          setKycData(updatedKycData);

          form.append("uid", uid);

          const res = await uploadKyc(form);
          if (res.code === 200) {
            toast.success("KYC Detail Uploaded Successfully.", {
              position: "top-right",
              autoClose: true,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            });
          }
        } else {
          toast.error(
            "Image file size is too large. Maximum file size is 300kb",
            {
              position: "top-right",
              autoClose: true,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            }
          );
        }
      } else {
        toast.error("You can upload jpg, png and jpeg file only.", {
          position: "top-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      }
    } else {
      if (
        !file &&
        (kycdata.photo_verified === 0 || kycdata.photo_verified === 3)
      ) {
        toast.error("Please select a file to upload your photo.", {
          position: "top-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      }
      if (
        !file2 &&
        (kycdata.photo_id_verified === 0 || kycdata.photo_id_verified === 3)
      ) {
        toast.error("Please select a file to upload your photo id.", {
          position: "top-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      }
    }
    setLoading(false);
    setIsUploading(false); // Reset uploading flag
  };

  useEffect(() => {
    kycInfoData();
  }, []);
  return (
    <React.Fragment>
      <Head title="KYC Document"></Head>

      <BlockHead size="lg">
        <Loader visible={loading} />
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">KYC Document</BlockTitle>
            <BlockDes>
              <p>Please Complete KYC to Receive Payments</p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              className={`toggle btn btn-icon btn-trigger mt-n1 ${
                sm ? "active" : ""
              }`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <Card className="card-bordered">
          <div className="card-inner-group">
            {kycdata.photo_verified == 1 ? (
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>Photo Uploaded</h6>
                    <p>Your Photo uploaded and waiting for approval</p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        <Badge className="badge-dim" color="warning">
                          Waiting for approval
                        </Badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : kycdata.photo_verified == 2 ? (
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>Photo Uploaded</h6>
                    <p>Your Photo uploaded and accepted</p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        <Badge className="badge-dim" color="success">
                          Accepted
                        </Badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>Upload Photo</h6>
                    <p>
                      {kycdata.photo_verified == 3 ? (
                        <div
                          className="alert alert-danger alert-dim mr-4 p-0 px-1 fs-5"
                          style={{ fontSize: 12, border: "none" }}
                          color="danger"
                        >
                          Your document rejected please upload again.
                          {kycdata.user_photo_remark &&
                            " Reason - " + kycdata.user_photo_remark}
                        </div>
                      ) : (
                        "Upload your passport size photo"
                      )}
                    </p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        {kycdata.user_photo && (
                          <a
                            href={kycdata.user_photo}
                            target="_blank"
                            className="btn btn-primary mt-2 mr-2"
                          >
                            <Icon name="eye" /> &nbsp;Preview
                          </a>
                        )}
                        <a href="javascript:;" className="btn btn-primary mt-2">
                          <input
                            type="file"
                            className="sm-input"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                if (validateFile(file)) {
                                  // Handle file here, e.g.,
                                  setFile(file);
                                  console.log("File accepted:", file);
                                }
                                // Reset the file input value to allow the same file to be selected again
                                e.target.value = null;
                              }
                            }}
                            accept="application/pdf, image/*"
                          />

                          {file !== "" ? "1 file selected" : "Upload Photo"}
                        </a>
                        {/* <Button color="primary">Upload Photo</Button> */}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}

            {kycdata.photo_id_verified == 1 ? (
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>Photo ID Uploaded</h6>
                    <p>Your photo id uploaded and waiting for approval</p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        <Badge className="badge-dim" color="warning">
                          Waiting for approval
                        </Badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : kycdata.photo_id_verified == 2 ? (
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>Photo Id Uploaded</h6>
                    <p>Photo id uploaded and accepted</p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        <Badge className="badge-dim" color="success">
                          Accepted
                        </Badge>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>Upload Photo ID</h6>
                    <p>
                      {kycdata.photo_id_verified == 3 ? (
                        <div
                          className="alert alert-danger alert-dim mr-4 p-0 px-1 fs-5"
                          style={{ fontSize: 12, border: "none" }}
                          color="danger"
                        >
                          Your document rejected please upload again.
                          {kycdata.user_photo_id_remark &&
                            " Reason - " + kycdata.user_photo_id_remark}
                        </div>
                      ) : (
                        "A government issued photo id proof"
                      )}
                    </p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        {kycdata.user_photo_id && (
                          <a
                            href={kycdata.user_photo_id}
                            target="_blank"
                            className="btn btn-primary mt-2 mr-2"
                          >
                            <Icon name="eye" /> &nbsp;Preview
                          </a>
                        )}
                        <a href="javascript:;" className="btn btn-primary mt-2">
                          <input
                            type="file"
                            className="sm-input"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                if (validateFile(file)) {
                                  // Handle file here, e.g.,
                                  setFile2(file);
                                  console.log("File accepted:", file);
                                }
                                // Reset the file input value to allow the same file to be selected again
                                e.target.value = null;
                              }
                            }}
                            accept="application/pdf, image/*"
                          />
                          {file2 !== "" ? "1 file selected" : "Upload Photo ID"}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </Card>
        {/* <div className="mt-3">
          {kycdata.photo_id_verified == 0 && kycdata.photo_verified == 0 && (
            <Button
              color="primary"
              disabled={file == "" && file2 == "" ? true : false}
              onClick={() => {
                if (file !== "" || file2 !== "") {
                  uploadImage();
                }
              }}
            >
              Submit KYC Documents
            </Button>
          )}
          {kycdata.photo_verified == 3 || kycdata.photo_id_verified == 3 ? (
            <Button
              color="primary"
              disabled={file == "" && file2 == "" ? true : false}
              onClick={() => {
                if (kycdata.photo_verified) {
                  uploadImage();
                }
              }}
            >
              Submit KYC Documents
            </Button>
          ) : (
            ""
          )}
          {kycdata.photo_verified == 0 && kycdata.photo_id_verified == 1 ? (
            <Button
              color="primary"
              disabled={file == "" ? true : false}
              onClick={() => {
                if (file !== "") {
                  uploadImage();
                }
              }}
            >
              Submit KYC Documents
            </Button>
          ) : (
            ""
          )}
          {kycdata.photo_id_verified == 0 && kycdata.photo_verified == 1 ? (
            <Button
              color="primary"
              disabled={file2 == "" ? true : false}
              onClick={() => {
                if (file2 !== "") {
                  uploadImage();
                }
              }}
            >
              Submit KYC Documents
            </Button>
          ) : (
            ""
          )}

          {kycdata.photo_id_verified == 0 && kycdata.photo_verified == 2 ? (
            <Button
              color="primary"
              disabled={file2 == "" ? true : false}
              onClick={() => {
                if (file2 !== "") {
                  uploadImage();
                }
              }}
            >
              Submit KYC Documents
            </Button>
          ) : (
            ""
          )}

          {kycdata.photo_id_verified == 2 && kycdata.photo_verified == 0 ? (
            <Button
              color="primary"
              disabled={file2 == "" ? true : false}
              onClick={() => {
                if (file2 !== "") {
                  uploadImage();
                }
              }}
            >
              Submit KYC Documents
            </Button>
          ) : (
            ""
          )}
        </div> */}
        <div className="mt-3">
          <Button
            color="primary"
            disabled={
              isUploading ||
              !(
                ((kycdata.photo_verified === 3 ||
                  kycdata.photo_id_verified === 3) &&
                  (file !== "" || file2 !== "")) ||
                (kycdata.photo_verified === 0 &&
                  kycdata.photo_id_verified === 1 &&
                  file !== "") ||
                (kycdata.photo_id_verified === 0 &&
                  kycdata.photo_verified === 1 &&
                  file2 !== "") ||
                (kycdata.photo_id_verified === 0 &&
                  kycdata.photo_verified === 2 &&
                  file2 !== "") ||
                (kycdata.photo_id_verified === 2 &&
                  kycdata.photo_verified === 0 &&
                  file2 !== "") ||
                (kycdata.photo_id_verified === 0 &&
                  kycdata.photo_verified === 0 &&
                  (file !== "" || file2 !== ""))
              )
            }
            onClick={async () => {
              if (
                ((kycdata.photo_verified === 3 ||
                  kycdata.photo_id_verified === 3) &&
                  (file !== "" || file2 !== "")) ||
                (kycdata.photo_verified === 0 &&
                  kycdata.photo_id_verified === 1 &&
                  file !== "") ||
                (kycdata.photo_id_verified === 0 &&
                  kycdata.photo_verified === 1 &&
                  file2 !== "") ||
                (kycdata.photo_id_verified === 0 &&
                  kycdata.photo_verified === 2 &&
                  file2 !== "") ||
                (kycdata.photo_id_verified === 2 &&
                  kycdata.photo_verified === 0 &&
                  file2 !== "") ||
                (kycdata.photo_id_verified === 0 &&
                  kycdata.photo_verified === 0 &&
                  (file !== "" || file2 !== ""))
              ) {
                await uploadImage();
              }
            }}
          >
            Submit KYC Documents
          </Button>
        </div>
      </Block>
    </React.Fragment>
  );
};
export default UserKYC;
