import React, { useEffect } from 'react'
import { useParams } from 'react-router';
import { validateToken } from '../../app/api';
import { Block } from '../../components/Component';
import Head from '../../layout/head/Head';
import PageContainer from '../../layout/page-container/PageContainer';
import { getUidByEmail } from '../../app/api';

const BecomeAdvertiser = () => {
    let {email} = useParams();
    const setUid = async(email) => {
     let mail = (window.atob(email))
     const res = await getUidByEmail(mail);
     if(res.code == 200){
        localStorage.setItem('uid',window.atob(res.data))
        window.location.href = `${process.env.PUBLIC_URL}/switch-account`
     }
    };
    useEffect(()=>{
        setUid(email);
    },[]);
    return (  
        <React.Fragment>
        <Head title="Auto Login" />
        <PageContainer>
            <Block className="nk-block-middle nk-auth-body  wide-xs">

            <h4 className='text-center text-primary'>Please wait...</h4>
            </Block>
        </PageContainer>
        </React.Fragment>
    )
      
    
}

export default BecomeAdvertiser;