import React, { useState, useEffect, useContext } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import {
  Card,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonGroup,
} from "reactstrap";
import {
  Block,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  PaginationComponent,
  BlockBetween,
  DataTableBody,
  DataTableHead,
  DataTableRow,
  DataTableItem,
  ReactDataTable,
} from "../components/Component";
import {
  getAdUnitByWeb,
  getCountryList,
  getReportData,
  getWebList,
} from "../app/api";
import Loader from "../app/Loader";
import { toast, ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import exportFromJSON from "export-from-json";
import AppContext from "../context/AppContext";

export default function ReportComponent({
  setStat,
  website = "",
  website_name = "",
  adunit_id = "",
  setAdUniId,
  adunit_name = "",
  setAdUniName,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [pgs, setPgs] = useState(0);

  const [gby, setGby] = useState("created");
  // const [gbyCol, setGbyCol] = useState('date');
  const [sm, updateSm] = useState(false);
  const [adtype, setAdtype] = useState(adunit_name);
  const [adtp, setAdtp] = useState(adunit_id);
  const [loading, setLoading] = useState(false);

  const [totals, setTotals] = useState(null);

  const [adUnits, setAdUnits] = useState("");

  const [rangeDate, setRangeDate] = useState({
    start: new Date(new Date().setDate(new Date().getDate() - 7)),
    end: new Date(),
  });

  const [rdata, setData] = useState([]);

  const ctx = useContext(AppContext);

  const getCampReport = async (gtype = "", pageNumber, limit = 0) => {
    setLoading(true);
    // let date_to = start ? getDate(start) : getDate(rangeDate.start);
    // let date_from = end ? getDate(end) : getDate(rangeDate.end);
    let date_to = getDate(rangeDate.start);
    let date_from = getDate(rangeDate.end);
    let itemLim = limit > 0 ? limit : itemPerPage;
    let pgNum = pageNumber ? pageNumber : 1;

    if (!rangeDate.end) {
      setRangeDate({ ...rangeDate, end: new Date() });
    }

    if (!rangeDate.start) {
      setRangeDate({ ...rangeDate, start: new Date(), end: new Date() });
    }

    let uid = localStorage.getItem("uid");

    let gtype2 = gtype ? gtype : "date";
    let res = await getReportData({
      uid: uid,
      from_date: date_from,
      to_date: date_to,
      group_by: gtype2,
      country: cnType,
      domain: webId,
      placement: adtp,
      lim: itemLim,
      page: pgNum,
    });
    if (res) {
      setData(res.data);
      setTotals(res.total);
      setPgs(res.row);
      ctx.dispatch({
        type: "stats",
        stats: res.data,
      });
    }

    ctx.dispatch({
      type: "wlt",
      wlt: res.wallet,
    });
    setLoading(false);
  };

  const onRangeChange = (dates) => {
    const [start, end] = dates;
    setRangeDate({ start: start, end: end });
  };

  const getDate = (ndate) => {
    let newDate = ndate ? ndate : new Date();
    const year = newDate.getFullYear();
    const month = newDate.getMonth() + 1;
    const d = newDate.getDate();

    return `${year}-${month.toString().padStart(2, "0")}-${d
      .toString()
      .padStart(2, "0")}`;
  };

  const onReportSearch = async () => {
    let type = "";
    if (gby === "created") {
      type = "date";
    } else if (gby === "web") {
      type = "domain";
    } else if (gby === "ad_name") {
      type = "adunit_id";
    } else {
      type = gby;
    }

    getCampReport(type);
  };

  const onAdtypeChange = async (text, val) => {
    setAdtype(text);
    setAdtp(val);
  };

  const exportExcel = () => {
    const fileName = "report";
    const exportType = exportFromJSON.types.xls;

    const ex_data = [];
    rdata.map((item) => {
      if (gby == "created") {
        ex_data.push({
          Date: item.created,
          Impression: item.Imprs,
          Click: item.Clicks,
          CTR: item.CTR,
          Amount: item.Total,
        });
      } else if (gby == "web") {
        ex_data.push({
          Domain: item.web,
          Impression: item.Imprs,
          Click: item.Clicks,
          CTR: item.CTR,
          Amount: item.Total,
        });
      } else if (gby == "ad_name") {
        ex_data.push({
          Placement: item.ad_name,
          Impression: item.Imprs,
          Click: item.Clicks,
          CTR: item.CTR,
          Amount: item.Total,
        });
      } else if (gby == "country") {
        ex_data.push({
          Country: item.country,
          Impression: item.Imprs,
          Click: item.Clicks,
          CTR: item.CTR,
          Amount: item.Total,
        });
      } else if (gby == "device_type") {
        ex_data.push({
          DeviceType: item.device_type,
          Impression: item.Imprs,
          Click: item.Clicks,
          CTR: item.CTR,
          Amount: item.Total,
        });
      } else if (gby == "device_os") {
        ex_data.push({
          DeviceOS: item.device_os,
          Impression: item.Imprs,
          Click: item.Clicks,
          CTR: item.CTR,
          Amount: item.Total,
        });
      }
    });

    if (gby == "created") {
      ex_data.push({
        Date: "Total",
        Impression: totals.total_impression,
        Click: totals.total_click,
        CTR: totals.total_ctr,
        Amount: totals.total_amount,
      });
    } else if (gby == "web") {
      ex_data.push({
        Domain: "Total",
        Impression: totals.total_impression,
        Click: totals.total_click,
        CTR: totals.total_ctr,
        Amount: totals.total_amount,
      });
    } else if (gby == "ad_name") {
      ex_data.push({
        Placement: "Total",
        Impression: totals.total_impression,
        Click: totals.total_click,
        CTR: totals.total_ctr,
        Amount: totals.total_amount,
      });
    } else if (gby == "country") {
      ex_data.push({
        Country: "Total",
        Impression: totals.total_impression,
        Click: totals.total_click,
        CTR: totals.total_ctr,
        Amount: totals.total_amount,
      });
    } else if (gby == "device_type") {
      ex_data.push({
        DeviceType: "Total",
        Impression: totals.total_impression,
        Click: totals.total_click,
        CTR: totals.total_ctr,
        Amount: totals.total_amount,
      });
    } else if (gby == "device_os") {
      ex_data.push({
        DeviceOS: "Total",
        Impression: totals.total_impression,
        Click: totals.total_click,
        CTR: totals.total_ctr,
        Amount: totals.total_amount,
      });
    }

    let data = ex_data;
    exportFromJSON({ data, fileName, exportType });
  };

  const getUnitsData = async (webcode) => {
    let uid = localStorage.getItem("uid");
    let res = await getAdUnitByWeb({
      uid: uid,
      web_code: webcode,
    });

    if (res) {
      setAdUnits(res.data);
    }
  };

  const [tooltipOpen5, setOpen5] = useState(false);
  const toggle5 = () => {
    setOpen5(!tooltipOpen5);
  };

  const [countries, setCountries] = useState("");
  const [cnType, setCnType] = useState("");
  const getCountries = async () => {
    let res = await getCountryList();
    setCountries(res);
  };

  const [webLists, setWebLists] = useState("");
  const [webId, seWebId] = useState(website);
  const [webName, seWebName] = useState(website_name);
  const getWebsiteListData = async () => {
    let uid = localStorage.getItem("uid");
    let res = await getWebList({
      uid: uid,
    });
    if (res.code == 200) {
      setWebLists(res.data);
    }
  };

  console.log(webName);

  const [srOpt, setSrOpt] = useState({
    col: "",
    sorting: "asc",
  });

  const sortData = (col) => {
    // setData()
    let data = ctx.sts.stats;
    if (srOpt.col == col && srOpt.sorting == "asc") {
      data.sort((a, b) => (a[col] < b[col] ? 1 : -1));
      setSrOpt({
        col: col,
        sorting: "desc",
      });
    } else {
      let data = ctx.sts.stats;
      data.sort((a, b) => (a[col] > b[col] ? 1 : -1));
      setSrOpt({
        col: col,
        sorting: "asc",
      });
    }

    ctx.dispatch({
      type: "stats",
      stats: data,
    });
    // setData(data);
    // console.log(rdata)
  };

  const cols = [
    {
      name: "Date",
      selector: (row) => row.created,
      sortable: true,
    },
    {
      name: "Impressions",
      selector: (row) => row.Impression,
      sortable: true,
    },
    {
      name: "Clicks",
      selector: (row) => row.Clicks,
      sortable: true,
    },
    {
      name: "CTR",
      selector: (row) => row.Clicks,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.Clicks,
      sortable: true,
    },
  ];

  const paginate = (pageNumber) => {
    console.log(pageNumber);
    setCurrentPage(pageNumber);
    if (currentPage !== pageNumber) {
      getCampReport(gby, pageNumber);
    }
  };

  useEffect(() => {
    getWebsiteListData();
    if (website) {
      getUnitsData(website);
    }
    getCountries();
    getCampReport();
  }, []);

  return (
    <>
      <Head title="Statistics"></Head>
      <Content>
        <Loader visible={loading} />
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>
                {"Statistics"}
                {/* {(webName) ? 'Website' : 'Statisticsssss' } */}
              </BlockTitle>
              {/* <h6 className="title">{webName} <Icon name="arrow-right" /> {adunit_name &&  adunit_name} {adunit_name &&  <Icon name="arrow-right" /> }  Statistics</h6> */}
              {/* <BlockDes className="text-soft">
                {(webName.length > 25 ) ? webName.substring(0,25)+'...' : webName}
                {webName && <Icon name="arrow-right" />} 
                {adunit_name &&  adunit_name} { adunit_name &&  <Icon name="arrow-right" />}  
                {webName && 'Statistics'}
              </BlockDes> */}
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand mr-n1 ${
                    sm ? "active" : ""
                  }`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div
                  className="toggle-expand-content"
                  style={{ display: sm ? "block" : "none" }}
                >
                  <ul className="nk-block-tools g-3">
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                        >
                          <Icon
                            name="filter-alt"
                            className="d-none d-sm-inline"
                          ></Icon>
                          <span
                            style={{
                              width: "230px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                          >
                            By {cnType.length ? cnType : "Country"}
                          </span>
                          <Icon name="chevron-right" className="dd-indc"></Icon>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <ul
                            className="link-list-opt no-bdr"
                            style={{ overflowY: "scroll", height: 240 }}
                          >
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setCnType("");
                                }}
                              >
                                <span>All</span>
                                {cnType === "" && (
                                  <Icon
                                    name="check"
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      fontSize: "14px",
                                    }}
                                  />
                                )}
                              </DropdownItem>
                            </li>
                            {countries &&
                              countries.map((item, key) => {
                                return (
                                  <li key={key}>
                                    <DropdownItem
                                      tag="a"
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        setCnType(item.label);
                                      }}
                                    >
                                      <span>{item.label}</span>
                                      {cnType === item.label && (
                                        <Icon
                                          name="check"
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                            fontSize: "14px",
                                          }}
                                        />
                                      )}
                                    </DropdownItem>
                                  </li>
                                );
                              })}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          tag="a"
                          className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                        >
                          <Icon
                            name="filter-alt"
                            className="d-none d-sm-inline"
                          ></Icon>
                          <span>
                            By{" "}
                            {webName
                              ? webName.length > 20
                                ? webName.substring(0, 20) + "..."
                                : webName
                              : "Website"}
                          </span>
                          <Icon name="chevron-right" className="dd-indc"></Icon>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <ul
                            className={
                              "link-list-opt no-bdr " +
                              (webLists.length > 3 ? "scrollweb" : " ")
                            }
                          >
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  seWebId("");
                                  seWebName("");
                                }}
                              >
                                <span>All</span>
                                {webId === "" && (
                                  <Icon
                                    name="check"
                                    style={{
                                      position: "absolute",
                                      right: "10px",
                                      fontSize: "14px",
                                    }}
                                  />
                                )}
                              </DropdownItem>
                            </li>

                            {webLists &&
                              webLists.map((item2, key) => {
                                return (
                                  <li key={key}>
                                    <DropdownItem
                                      tag="a"
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        seWebId(item2.web_code);
                                        seWebName(item2.webname);
                                        getUnitsData(item2.web_code);
                                      }}
                                    >
                                      <span>{item2.webname}</span>
                                      {webId === item2.web_code && (
                                        <Icon
                                          name="check"
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                            fontSize: "14px",
                                          }}
                                        />
                                      )}
                                    </DropdownItem>
                                  </li>
                                );
                              })}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li>
                      <UncontrolledDropdown disabled={false}>
                        <DropdownToggle
                          tag="a"
                          className="dropdown-toggle btn btn-white btn-dim btn-outline-light"
                        >
                          <Icon
                            name="filter-alt"
                            className="d-none d-sm-inline"
                          ></Icon>
                          <span>By {adtype ? adtype : "All Ad Unit"}</span>
                          <Icon name="chevron-right" className="dd-indc"></Icon>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <ul
                            className={
                              "link-list-opt no-bdr " +
                              (adUnits.length > 3 ? "scrollweb" : " ")
                            }
                          >
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  onAdtypeChange("", "");
                                }}
                              >
                                {" "}
                                <span>All Ad Unit</span>
                              </DropdownItem>
                            </li>
                            {adUnits &&
                              adUnits.map((itm, key) => {
                                return (
                                  <li key={key}>
                                    <DropdownItem
                                      tag="a"
                                      href="#dropdownitem"
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        onAdtypeChange(
                                          itm.ad_name,
                                          itm.ad_code
                                        );
                                      }}
                                    >
                                      <span>{itm.ad_name}</span>
                                      {adtype === itm.ad_name && (
                                        <Icon
                                          name="check"
                                          style={{
                                            position: "absolute",
                                            right: "10px",
                                            fontSize: "14px",
                                          }}
                                        />
                                      )}
                                    </DropdownItem>
                                  </li>
                                );
                              })}
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                    <li>
                      <div style={{ position: "relative" }}>
                        <DatePicker
                          selected={rangeDate.start}
                          startDate={rangeDate.start}
                          onChange={onRangeChange}
                          endDate={rangeDate.end}
                          selectsRange
                          className="form-control date-picker"
                          dateFormat="dd-M-yyyy"
                          maxDate={new Date()}
                        />
                      </div>
                    </li>
                    <li className="nk-block-tools-opt">
                      <Button color="primary" onClick={onReportSearch}>
                        <Icon name="search"></Icon>
                        <span>Search</span>
                      </Button>
                      &nbsp;
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Card className="card-bordered card-full">
            <div className="card-inner reportbox">
              <div className="card-title-group mt-1">
                <div className="mb-1">
                  <ButtonGroup>
                    <Button
                      outline={gby == "created" ? false : true}
                      onClick={() => {
                        setGby("created");
                        getCampReport("date");
                      }}
                      color="light"
                    >
                      Date
                    </Button>
                    <Button
                      outline={gby == "web" ? false : true}
                      onClick={() => {
                        setGby("web");
                        getCampReport("domain");
                      }}
                      color="light"
                    >
                      Domain
                    </Button>
                    <Button
                      outline={gby == "ad_name" ? false : true}
                      onClick={() => {
                        setGby("ad_name");
                        getCampReport("adunit_id");
                      }}
                      color="light"
                    >
                      Placement
                    </Button>
                    <Button
                      outline={gby == "country" ? false : true}
                      onClick={() => {
                        setGby("country");
                        getCampReport("country");
                      }}
                      color="light"
                    >
                      Country
                    </Button>
                    <Button
                      outline={gby == "device_type" ? false : true}
                      onClick={() => {
                        setGby("device_type");
                        getCampReport("device_type");
                      }}
                      color="light"
                    >
                      Device
                    </Button>
                    <Button
                      outline={gby == "device_os" ? false : true}
                      onClick={() => {
                        setGby("device_os");
                        getCampReport("device_os");
                      }}
                      color="light"
                    >
                      Operating System
                    </Button>
                    {/* <Button outline color="primary">Placement</Button> */}
                  </ButtonGroup>
                </div>
                <div className="card-tools">
                {ctx.sts.perms.includes('444') || ctx.sts.perms.includes('9999') ?
                  <a href="javascript:;" onClick={exportExcel} className="m-1">
                    <Icon name="file-download" />
                    Export CSV
                  </a>
                  :''}
                  {webName && (
                    <>
                      <Button
                        className={"m-1"}
                        size="sm"
                        color="primary"
                        onClick={() => {
                          setStat(false);
                          setAdUniId(false);
                          setAdUniName(false);
                        }}
                      >
                        <Icon name="chevrons-left" size="sm" /> Go Back&nbsp;
                        {/* <Icon name="chevrons-left" size="sm" />{" "}
              Go Back &nbsp; */}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div
              className="ml-4 mr-4 repcolor boxscroll"
              style={{
                borderLeft: "1px solid #ddd",
                borderRight: "1px solid #ddd",
              }}
            >
              <DataTableBody
                className="border-top"
                bodyclass="nk-tb-orders pr-0 pl-0"
              >
                <DataTableHead>
                  <DataTableRow className="text-muted">
                    <span>
                      {gby == "created"
                        ? "Date"
                        : gby == "web"
                        ? "Domain"
                        : gby == "ad_name"
                        ? "Placement"
                        : gby == "country"
                        ? "Country"
                        : gby == "device_type"
                        ? "Device Format"
                        : gby == "device_os"
                        ? "Operating System"
                        : ""}
                    </span>
                    &nbsp;{" "}
                    <Icon
                      name="exchange-v"
                      className="sm-ico-sort"
                      onClick={() => sortData(gby)}
                    />
                  </DataTableRow>
                  <DataTableRow className="text-muted">
                    <span>Impressions</span>
                    &nbsp;{" "}
                    <Icon
                      name="exchange-v"
                      className="sm-ico-sort"
                      onClick={() => sortData("Imprs")}
                    />
                  </DataTableRow>
                  <DataTableRow className="text-muted">
                    <span>Clicks</span>
                    &nbsp;{" "}
                    <Icon
                      name="exchange-v"
                      className="sm-ico-sort"
                      onClick={() => sortData("Clicks")}
                    />
                  </DataTableRow>
                  <DataTableRow className="text-muted">
                    <span>CTR</span>
                    &nbsp;{" "}
                    <Icon
                      name="exchange-v"
                      className="sm-ico-sort"
                      onClick={() => sortData("CTR")}
                    />
                  </DataTableRow>
                  {/* <DataTableRow className="text-muted">
              <span>CPM</span>
            </DataTableRow> */}
                  <DataTableRow className="text-muted text-right">
                    <span>Amount</span>
                    &nbsp;{" "}
                    <Icon
                      name="exchange-v"
                      className="sm-ico-sort"
                      onClick={() => sortData("Total")}
                    />
                  </DataTableRow>
                </DataTableHead>
                {ctx.sts.stats &&
                  ctx.sts.stats.map((item, key) => {
                    return (
                      <DataTableItem key={key}>
                        <DataTableRow>
                          <span className="text-muted">
                            {gby == "created"
                              ? item.created
                              : gby == "web"
                              ? item.web
                              : gby == "ad_name"
                              ? item.ad_name
                              : gby == "country"
                              ? item.country
                              : gby == "device_type"
                              ? item.device_type
                              : gby == "device_os"
                              ? item.device_os
                              : ""}
                          </span>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="tb-sub text-muted">
                            {item.Imprs}
                          </span>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="tb-sub text-muted">
                            {item.Clicks}
                          </span>
                        </DataTableRow>
                        <DataTableRow>
                          <span className="tb-sub text-muted">{item.CTR}</span>
                        </DataTableRow>
                        {/* <DataTableRow size="lg">
                  <span className="tb-sub text-primary">asd</span>
                </DataTableRow> */}
                        <DataTableRow className="text-right">
                          <span className="tb-sub tb-amount">
                            <span>${item && item.Total.toFixed(5)}</span>
                          </span>
                        </DataTableRow>
                      </DataTableItem>
                    );
                  })}
                <DataTableHead bgColor="#fff">
                  <DataTableRow className="text-dark">
                    <span className="tb-lead fw-bold title text-white">
                      Total
                    </span>
                  </DataTableRow>
                  <DataTableRow className="text-muted">
                    <span>{totals && totals.total_impression}</span>
                  </DataTableRow>
                  <DataTableRow className="text-muted">
                    <span>{totals && totals.total_click}</span>
                  </DataTableRow>
                  <DataTableRow className="text-muted">
                    <span>{totals && totals.total_ctr}</span>
                  </DataTableRow>
                  <DataTableRow className="text-dark text-right">
                    <span className="fw-bold title">
                      ${totals && totals.total_amount.toFixed(5)}
                    </span>
                  </DataTableRow>
                </DataTableHead>
              </DataTableBody>
            </div>

            <div className="card-inner" style={{ display: "flex" }}>
              <div style={{ alignSelf: "self-start", width: "97%" }}>
                {ctx.sts.stats.length > 0 && ctx.sts.stats.length > 0 ? (
                  <PaginationComponent
                    itemPerPage={itemPerPage}
                    totalItems={pgs}
                    paginate={paginate}
                    currentPage={currentPage}
                  />
                ) : (
                  <div className="text-center">
                    <span className="text-silent">No record found !</span>
                  </div>
                )}
              </div>

              <div style={{ alignSelf: "end" }}>
                <UncontrolledDropdown>
                  <DropdownToggle
                    tag="a"
                    className="dropdown-toggle bg-white btn btn-sm btn-outline-light btn-icon"
                  >
                    &nbsp; &nbsp; {itemPerPage}{" "}
                    <Icon name="downward-ios"></Icon>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <ul className="link-list-opt no-bdr">
                      <li onClick={() => {}}>
                        <DropdownItem
                          tag="a"
                          href="#"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setItemPerPage(10);
                            getCampReport(gby, 1, 10);
                          }}
                        >
                          <span>10</span>
                        </DropdownItem>
                      </li>
                      <li onClick={() => {}}>
                        <DropdownItem
                          tag="a"
                          href="#"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setItemPerPage(20);
                            getCampReport(gby, 1, 20);
                          }}
                        >
                          <span>20</span>
                        </DropdownItem>
                      </li>
                      <li onClick={() => {}}>
                        <DropdownItem
                          tag="a"
                          href="#"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setItemPerPage(50);
                            getCampReport(gby, 1, 50);
                          }}
                        >
                          <span>50</span>
                        </DropdownItem>
                      </li>
                      <li onClick={() => {}}>
                        <DropdownItem
                          tag="a"
                          href="#"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setItemPerPage(100);
                            getCampReport(gby, 1, 100);
                          }}
                        >
                          <span>100</span>
                        </DropdownItem>
                      </li>
                      <li onClick={() => {}}>
                        <DropdownItem
                          tag="a"
                          href="#"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setItemPerPage(500);
                            getCampReport(gby, 1, 500);
                          }}
                        >
                          <span>500</span>
                        </DropdownItem>
                      </li>
                    </ul>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </div>
          </Card>
        </Block>

        <ToastContainer />
      </Content>
    </>
  );
}
