import React, {useContext, useEffect, useState } from "react";
import UserAvatar from "../../../user/UserAvatar";
import { activityData } from "./ActivityData";
import {
  Badge,
  CardTitle, 
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem, 
} from "reactstrap";
import { getNotification } from "../../../../app/api";
import Loader from "../../../../app/Loader";
import { Icon, PaginationComponent } from "../../../Component";
import Moment from "react-moment";
import AppContext from "../../../../context/AppContext";

const RecentActivity = ({setModal, appState}) => {

  const [recentUser, setRecentUser] = useState("");
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [itemPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pgs, setPgs] = useState(0);
  const ctx = useContext(AppContext);
  const getNoti = async (pg = 1, lim = 0) => {
    
    setLoading(true);
    let itemLim = lim > 0 ? lim : itemPerPage;
    let res = await getNotification(pg, itemLim);
    
    if(res.code === 200) {
      setData(res.data);
      setPgs(res.row);
      ctx.dispatch({
        type:'wlt',
        wlt: res.wallet
      });
      ctx.dispatch({
        type:"noti",
        data:res.data,
        row:res.count
    });
    }
    // console.log(res.data);
    setLoading(false);
  }

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (currentPage !== pageNumber) {
      getNoti(pageNumber);
    }
  };
  
  useEffect(()=> {
    getNoti();
  }, []);
  
  return (
    <React.Fragment>
      <Loader visible={loading} />
      <div className="card-inner border-bottom">
        <div className="card-title-group">
          <CardTitle>
            <h6 className="title">Recent Notifications <Icon name="fa fa-user" /></h6>
          </CardTitle>
        </div> 
      </div>
      <ul className="nk-activity">
        {data.length > 0 
          ? data.map((item, key) => {
            let cls = (item.view == 1) ? 'nk-activity-item sm-bg-light' : 'nk-activity-item';
            let tag = (item.view == 0) ? <Badge pill color="danger">New</Badge> : '';
            {/* {console.log('dd'+data.length)} */}
            return ( 
                <a href="javascript:;" key={key} onClick={() => {
                  appState.dispatch({
                    type:'notiup',
                    ntitle:item.title,
                    ndesc:item.noti_desc,
                    durl:item.display_url,
                    nid:item.notifuser_id
                  })
                }}>
                <li className={cls} style={{borderBottom:'1px solid #eee'}} >
                  {item.noti_type == 1 ? 
                  <UserAvatar
                    className="nk-activity-media"
                    theme="primary"
                    image={item.img}
                    text={item.initial}
                  >
                  <Icon name="bell" style={{fontSize:20}} />
                  </UserAvatar>
                  :
                  <UserAvatar
                      className="nk-activity-media" 
                      theme="warning" 
                      image={item.img} 
                      text={item.initial} 
                    >
                    <Icon name="percent" style={{fontSize:20}} />
                  </UserAvatar>
                }
                  <div className="nk-activity-data">
                    <div className="label" style={{fontSize:14}}><b>{item.title}</b> &nbsp; {tag}</div>
                    <div style={{fontSize:14, color:"#555"}}>{item.noti_desc.length > 150 ? item.noti_desc.substr(0,140)+'...' : item.noti_desc }</div>
                    <span className="time"><Moment fromNow >{item.created_at}</Moment></span>
                  </div>
                </li>
                </a>
              );
            })
          : <div className="text-center p-3">You  don't have any notification.</div>}
      </ul>
      <div className="card-inner" style={{ display: "flex" }}>
        <div style={{ alignSelf: "self-start", width: "97%" }}>
          {data.length > 0 ? (
            <PaginationComponent
              itemPerPage={itemPerPage}
              totalItems={pgs}
              paginate={paginate}
              currentPage={currentPage}
            />
          ) : (
            // <div className="text-center">
            //   <span className="text-silent">No campaign found</span>
            // </div>
            ''
          )}
        </div>

        <div style={{ alignSelf: "end" }}>
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="dropdown-toggle bg-white btn btn-sm btn-outline-light btn-icon">
              &nbsp; &nbsp; {itemPerPage} <Icon name="downward-ios"></Icon>
            </DropdownToggle>
            <DropdownMenu right>
              <ul className="link-list-opt no-bdr">
                <li onClick={() => {}}>
                  <DropdownItem
                    tag="a"
                    href="#"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setItemPerPage(10);
                      getNoti(1, 10);
                    }}
                  >
                    <span>10</span>
                  </DropdownItem>
                </li>
                <li onClick={() => {}}>
                  <DropdownItem
                    tag="a"
                    href="#"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setItemPerPage(20);
                      getNoti(1, 20);
                    }}
                  >
                    <span>20</span>
                  </DropdownItem>
                </li>
                <li onClick={() => {}}>
                  <DropdownItem
                    tag="a"
                    href="#"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setItemPerPage(50);
                      getNoti(1, 50);
                    }}
                  >
                    <span>50</span>
                  </DropdownItem>
                </li>
                <li onClick={() => {}}>
                  <DropdownItem
                    tag="a"
                    href="#"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setItemPerPage(100);
                      getNoti(1, 100);
                    }}
                  >
                    <span>100</span>
                  </DropdownItem>
                </li>
                <li onClick={() => {}}>
                  <DropdownItem
                    tag="a"
                    href="#"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setItemPerPage(500);
                      getNoti(1, 500);
                    }}
                  >
                    <span>500</span>
                  </DropdownItem>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </React.Fragment>
  );
};
export default RecentActivity;
