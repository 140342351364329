import React from "react";
import Icon from "../icon/Icon";
import { Pagination, PaginationLink, PaginationItem } from "reactstrap";

const PaginationComponent = ({ itemPerPage, totalItems, paginate, currentPage }) => {
  let arr = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemPerPage); i++) {
    arr.push(i);
  }
  let first_page = 1;
  let last_page = arr.length;
  let diff = 2;
  let start_range = diff + first_page;
  let end_range = last_page - diff;

  let pageNumbers = [];

  if (currentPage <= start_range && end_range <= currentPage) {
    pageNumbers = [];
    let pstart = currentPage - 2;
    pstart = (pstart > 0 ) ? pstart : 1;
    let pend = currentPage + 2;
    pend = (pend > Math.ceil(totalItems / itemPerPage)) ?  Math.ceil(totalItems / itemPerPage) : pend;
    
    for (let i = pstart; i <= pend; i++) {
  
        pageNumbers.push(i);
    }
  }else{
    if (currentPage <= start_range && end_range > currentPage) {
      pageNumbers = [];
      for (let i = first_page; i <= start_range + 1; i++) {
        pageNumbers.push(i);
      }
      // if(last_page != start_range + 1){
        pageNumbers.push("...", last_page);
      // }
    }
  
    if (start_range < currentPage && end_range > currentPage) {
      pageNumbers.push(first_page, "...");
      for (let i = currentPage; i <= currentPage + diff; i++) {
        pageNumbers.push(i);
      }
      if (currentPage == last_page - start_range) {
        pageNumbers.push(last_page);
      } else {
        pageNumbers.push("...", last_page);
      }
    }
    if (start_range < currentPage && end_range <= currentPage) {
      pageNumbers.push(first_page, "...");
      for (let i = currentPage; i <= last_page; i++) {
        pageNumbers.push(i);
      }
    }
  }

  const nextPage = () => {
    paginate(currentPage + 1);
  };

  const prevPage = () => {
    paginate(currentPage - 1);
  };
  return (
    <Pagination aria-label="Page navigation example">
      <PaginationItem disabled={currentPage - 1 === 0 ? true : false}>
        <PaginationLink
          className="page-link-prev"
          onClick={(ev) => {
            ev.preventDefault();
            prevPage();
          }}
          href="#prev"
        >
          <Icon name="chevrons-left" />
          <span>Prev</span>
        </PaginationLink>
      </PaginationItem>
      {pageNumbers.map((item, idx) => {
        return (
          <PaginationItem className={currentPage === item ? "active" : ""} key={idx}>
            {item == "..." ? (
              <PaginationLink>{item}</PaginationLink>
            ) : (
              <PaginationLink
                tag="a"
                href="#pageitem"
                onClick={(ev) => {
                  ev.preventDefault();
                  paginate(item);
                }}
              >
                {item}
              </PaginationLink>
            )}
          </PaginationItem>
        );
      })}

      <PaginationItem disabled={pageNumbers[pageNumbers.length - 1] === currentPage}>
        <PaginationLink
          className="page-link-next"
          onClick={(ev) => {
            ev.preventDefault();
            nextPage();
          }}
          href="#next"
        >
          <span>Next</span>
          <Icon name="chevrons-right" />
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};
export default PaginationComponent;
