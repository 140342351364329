import React, { useState, useEffect, useContext } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import InvestOverview from "../components/partials/invest/invest-overview/InvestOverview";
import InvestPlan from "../components/partials/invest/invest-plan/InvestPlan";
import RecentInvest from "../components/partials/invest/recent-investment/RecentInvest";
import RecentActivity from "../components/partials/default/recent-activity/Activity";
import Notifications from "../components/partials/default/notification/Notification";
import { DropdownToggle, DropdownMenu, Card, UncontrolledDropdown, DropdownItem, Modal, ModalBody } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
  PreviewAltCard,
  TooltipComponent,
  BlockContent
} from "../components/Component";
import { BalanceBarChart, DepositBarChart, WithdrawBarChart } from "../components/partials/charts/invest/InvestChart";
import BrowserUser from "../components/partials/analytics/browser-users/BrowserUser";
import AudienceOverview from "../components/partials/analytics/audience-overview/AudienceOverview";
import SessionDevice from "../components/partials/analytics/session-devices/SessionDevice";
import SalesOverview from "../components/partials/default/sales-overview/SalesOverview";
import { Link } from "react-router-dom";
import CampData from "./Dashboard/CampData";
import { dashboardGraph, getPopupContent } from "../app/api";
// import SessionOs from "../components/partials/analytics/session-devices/SessionOs";
import AppContext from "../context/AppContext";
import ClickDevice from "../components/partials/analytics/session-devices/ClickDevice";
import ClickCountry from "../components/partials/analytics/session-devices/ClickCountry";
import ImpCountry from "../components/partials/analytics/session-devices/ImpCountry";
import WebData from "./Dashboard/WebData";
import Loader2 from "../app/Loader2";
import Loader from "../app/Loader";
import { ToastContainer } from "react-toastify";

const InvestHomePage = () => {
  const [sm, updateSm] = useState(false);
  const [days, setDays] = useState(0);
  const ctx = useContext(AppContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [modal, setModal] = useState(false);
  
  const adGraphData = async (day) => {
    setLoading(true)
    let uid = localStorage.getItem('uid');
    
    let res = await dashboardGraph({
      uid:uid, 
      option:day
    });
    
    setData(res);
    ctx.dispatch({
      type:'wlt',
      wlt: res.wallet
    });
    setLoading(false)
  }

  const capitalizeEveryWord = (sentence) => {
    return sentence.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  }

  const popupContent = async() => {
    let uid = localStorage.getItem('uid');
    let type = 1;
    let res = await getPopupContent(uid, type);
    if (res.code === 200) {
      setPopupData(res.data);
      setModal(true)
    } else {
      setModal(false);
      setPopupData(null);
    }
  }
  
  useEffect(()=>{
    adGraphData(days);
    if (localStorage.getItem('firstlogin') !== null) {
      if (localStorage.getItem('firstlogin') == 1) {
        popupContent();
      }
    }
    localStorage.removeItem('firstlogin');
  },[]);
  
  return (
    <>
    
    <React.Fragment>
      <Head title="Welcome to 7Search PPC" />
      <Content>
        {/* This popup is for first time login user */}
        {popupData !== null &&
        <Modal isOpen={modal} className="modal-dialog-centered" size="md" toggle={() => setModal(false)}>
          <div className="modal-header" style={{ display: "block" }}>
            <h5 className="modal-title" style={{ textAlign: "center" }}>{popupData != null && popupData.title}</h5>
            <a
              href="#cancel"
              onClick={(ev) => {
                ev.preventDefault();
                setModal(false);
                localStorage.removeItem('firstlogin');
              }}
              className="close"
              style={{ margin: "-2.7rem -1.60rem -2rem auto" }}
            >
              <Icon name="cross-sm"></Icon>
            </a>
          </div>
          <ModalBody>
            <div className="row">
              <div className="col-lg-12 text-center">
                <BlockContent>
                  <BlockDes>
                    <img 
                      style={{ width: '85%' }} 
                      className="mt-4 mb-3" 
                      src={`https://services.7searchppc.com/popup-images/${popupData != null && popupData.image}`} 
                      alt="logo-dark" 
                    />
                    <h4 className="mt-2 mb-3 hdcolor">{popupData != null && popupData.sub_title}</h4>
                    <h5 className="mt-2">
                      Dear, {capitalizeEveryWord(localStorage.getItem("fname") + ' ' + localStorage.getItem("lname"))}
                    </h5>
                    <span dangerouslySetInnerHTML={{ __html: (popupData != null && popupData.message) }}/>
                  </BlockDes>
                  <Button
                    type="submit"
                    color="primary"
                    size="lg"
                    onClick={() => {
                      window.location.href = popupData != null && popupData.btn_link;
                    }}
                    className="mt-3 mb-4"
                  >
                    {popupData != null && popupData.btn_content}
                  </Button>
                </BlockContent>
              </div>
            </div>
          </ModalBody>
        </Modal>
        }
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>
                <span style={{fontSize:24}}>
                  Welcome to 7Search PPC
                  </span>
                </BlockTitle>
              <BlockDes className="text-dark">
              <Loader visible={loading} />
                <p>Best Solution for All Your Website Monetization Needs.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button className={`btn-icon btn-trigger toggle-expand mr-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="more-v"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li>
                      <UncontrolledDropdown>
                        <DropdownToggle tag="a" className="dropdown-toggle btn btn-white btn-dim btn-outline-light">
                          <Icon name="filter-alt" className="d-none d-sm-inline"></Icon>
                          <span>{days == 0 ? 'Today' : days+' Days'}</span>
                          <Icon name="chevron-right" className="dd-indc"></Icon>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <ul className="link-list-opt no-bdr">
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setDays(0);
                                  adGraphData(0)
                                }}
                              > <span>Today</span>
                              {days === 0 && <Icon name="check" style={{position:'absolute', right:'10px', fontSize:'14px'}} />}
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setDays(7);
                                  adGraphData(7)
                                }}
                              >
                                <span>7 Days</span>
                                {days === 7 && <Icon name="check" style={{position:'absolute', right:'10px', fontSize:'14px'}} />}
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setDays(15)
                                  adGraphData(15)
                                }}
                              >
                                <span>15 Days</span>
                                {days === 15 && <Icon name="check" style={{position:'absolute', right:'10px', fontSize:'14px'}} />}
                              </DropdownItem>
                            </li>
                            <li>
                              <DropdownItem
                                tag="a"
                                href="#dropdownitem"
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  setDays(30)
                                  adGraphData(30)
                                }}
                              >
                                <span>30 Days</span>
                                {days === 30 && <Icon name="check" style={{position:'absolute', right:'10px', fontSize:'14px'}} />}
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block>
          <Row className="g-gs">
            <Col lg="6" xxl="6">
              <PreviewAltCard className="h-100">
                {data !== null && <SalesOverview adGraph={data.graph} />}
              </PreviewAltCard>
            </Col>
            <Col md="5" xxl="3">
              <Card className="card-bordered h-100">
                {data !== null && <WebData webData={data.website[0]} />}
              </Card>
            </Col>
            <Col md="5" xxl="3">
              <Card className="card-bordered h-100">
                {data !== null && <CampData campData={data.adunit} totalAd={data.total_adunit} />}
              </Card>
            </Col>
            <Col md="6" xxl="3">
              <PreviewAltCard className="h-100" bodyClass="h-100 stretch flex-column">
              {data !== null && <SessionDevice dvData={data.device_imp} />}
              </PreviewAltCard>
            </Col>
            <Col md="6" xxl="3">
              <PreviewAltCard className="h-100" bodyClass="h-100 stretch flex-column">
              {data !== null && <ClickDevice dvData={data.device_click} />}
              </PreviewAltCard>
            </Col>
            <Col md="6" xxl="3">
              <PreviewAltCard className="h-100" bodyClass="h-100 stretch flex-column">
              {data !== null && <ImpCountry dvData={data.country_imp} />}
              </PreviewAltCard>
            </Col>
            <Col md="6" xxl="3">
              <PreviewAltCard className="h-100" bodyClass="h-100 stretch flex-column">
              {data !== null && <ClickCountry dvData={data.country_click} />}
              </PreviewAltCard>
            </Col>
            {/* <Col md="6" xxl="3">
            <PreviewAltCard className="h-100" bodyClass="h-100 stretch flex-column">
              {data !== null && <SessionOs osData={data.os}/>}
              </PreviewAltCard>
            </Col> */}
            {/* <Col md="6" xxl="4">
              <Card className="card-bordered h-100">
              {data !== null && <BrowserUser country={data.country} />}
              </Card>
            </Col> */}
          </Row>
        </Block>
      </Content>
      <ToastContainer />
    </React.Fragment>
    </>
  );
};

export default InvestHomePage;
