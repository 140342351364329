import React, { useContext, useState, useEffect } from "react";
import CampaignContext from "../../context/CampaignContext";
import { Row, Col, FormGroup, Card } from "reactstrap";
import { Icon, Block } from "../../components/Component";
import { Link } from "react-router-dom";

export default function SuccessWeb({ title, type , updateAdUnits}) {
  useEffect(() => {}, []);

  return (
    <>
      <Block size="lg">
        <Row className="gy-3 mt-5">
          <Col md="8" className="text-center offset-md-2 mt-5 ">
            <Card
              className="card-bordered justify-content-center"
              style={{ height: "450px" }}
            >
              <FormGroup className="pl-5 pr-5">
                <Icon
                  name="check-circle"
                  className="text-primary"
                  style={{ fontSize: "120px" }}
                />
                <h4 className="text-primary mt-3 mb-3">
                  Congratulations! {title}{" "}
                  {type == "update"
                    ? "Successfully Updated"
                    : "added Successfully"}
                </h4>
                {type == "update" ? ( 
                  <p style={{ fontSize: "16px" }}>
                    {" "}
                    Your {title.toLowerCase()} has been successfully updated. Changes are now live!
                  </p>
                ) : (
                  (updateAdUnits === "updt") ? 
                    <p style={{ fontSize: "16px" }}>
                    {" "}
                    Your {title.toLowerCase()} has been successfully added. Changes are now live!
                  </p>
                  : <p style={{ fontSize: "16px" }}>
                  {" "}
                  You have added a new {title.toLowerCase()} successfully. It will take 3 to 5 business days for the review process.
                </p>
                 
                 
                )}
                {/* <p style={{fontSize:'16px'}}>Your {title} added successfully. Now you can add more ad unit</p> */}
                <br /> 
                <Link
                  to={`${process.env.PUBLIC_URL}/websites`}
                  className="mt-5"
                >
                  Back to List
                </Link>
              </FormGroup>
            </Card>
          </Col>
        </Row>
        {/* <div className="actions clearfix">
            <ul>
              <li>
                <Button color="primary" >
                  Previous
                </Button>
              </li>
            </ul>
          </div> */}
      </Block>
    </>
  );
}
