import React, { useEffect, useState } from "react";

const WebData = ({webData}) => {

  return (
    <React.Fragment>
      <div className="card-inner mb-n2">
        <div className="card-title-group">
          <div className="card-title card-title-sm">
            <h5 className="title">Your Websites</h5>
            <p className="title">You have total {webData.total_website} websites</p>
          </div>
        </div>
      </div>
      
      <div className="traffic-channel-group g-2">      
          <div className="traffic-channel-data">
            <div className="title pl-2">
              <span className="dot dot-lg sq" style={{ background: '#1ee0ac' }}></span>
              <span>Approved</span>
            </div>
            <div className="amount pl-2">
              <small>{webData.approved}</small>
            </div>
          </div>
          <div className="traffic-channel-data">
            <div className="title pl-2">
              <span className="dot dot-lg sq" style={{ background: "#EB2F48" }}></span>
              <span>Rejected</span>
            </div>
            <div className="amount pl-2">
              <small>{webData.reject}</small>
            </div>
          </div>
          <div className="traffic-channel-data">
            <div className="title pl-2">
              <span className="dot dot-lg sq" style={{ background: "#EB2F48" }}></span>
              <span>Suspended</span>
            </div>
            <div className="amount pl-2">
              <small>{webData.suspend}</small>
            </div>
          </div>
          <div className="traffic-channel-data">
            <div className="title pl-2">
              <span className="dot dot-lg sq" style={{ background: "#c797199e" }}></span>
              <span>Hold</span>
            </div>
            <div className="amount pl-2">
              <small>{webData.hold}</small>
            </div>
          </div>
          <div className="traffic-channel-data">
            <div className="title pl-2">
              <span className="dot dot-lg sq" style={{ background: "rgb(121, 139, 255)" }}></span>
              <span>In-Review</span>
            </div>
            <div className="amount pl-2">
              <small>{webData.inreview}</small>
            </div>
          </div>
          <div className="traffic-channel-data">
            <div className="title pl-2">
              <span className="dot dot-lg sq" style={{ background: "#EB2F48" }}></span>
              <span>Inactive</span>
            </div>
            <div className="amount pl-2">
              <small>{webData.inactive}</small>
            </div>
          </div>
        </div>
    
    </React.Fragment>
  );
};
export default WebData;
