import React, { useContext, useState, useEffect } from "react";
import CampaignContext from "../../context/CampaignContext";
import {
  Row,
  Col,
  Tooltip,
  Button,
  FormGroup,
  Form,
  Spinner,
  Popover,
  PopoverBody,
  PopoverHeader,
  Alert,
  CardBody,
  Card,
  CardTitle,
  CardText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Container
} from "reactstrap";
import {
  RSelect,
  Icon,
  BlockHead,
  BlockHeadContent,
  BackTo,
  BlockTitle,
  BlockDes,
  PreviewCard,
  Block,
  BlockContent,
  CodeBlock,
} from "../../components/Component";
import { adWebsite, getCategoryList,getWallet, checkMeta, checkWebsite } from "../../app/api";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import SuccessWeb from "./SuccessWeb";

import grid1 from "../../images/native-grid-1.png";
import grid2 from "../../images/native-grid-2.png";
import grid3 from "../../images/native-grid-3.png";
import grid4 from "../../images/native-grid-4.png";
import grid5 from "../../images/native-grid-5.png";
import grid6 from "../../images/native-grid-6.png";
import grid7 from "../../images/native-grid-7.png";
import grid8 from "../../images/native-grid-8.png";
import banner1 from "../../images/banner1.jpg";
import banner2 from "../../images/banner2.jpg";
import banner3 from "../../images/banner3.jpg";
import banner4 from "../../images/banner4.jpg";
import banner5 from "../../images/banner5.jpg";
import banner6 from "../../images/banner6.jpg";
import Loader from "../../app/Loader";
import AppContext from "../../context/AppContext";
import Lottie from "lottie-react";

export default function AdWebsite() {
  const ctx = useContext(AppContext);
  const showToast = (type) => {
    if (type == 1) {
      toast.success("Campaign saved successfully", {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        // closeButton: <CloseButton />,
      });
    } else {
      toast.error("Something went wrong", {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        // closeButton: <CloseButton />,
      });
    }
  };

  const { errors, register, handleSubmit, getValues, setValue, setError, clearErrors } = useForm();

  const [fsave, setFsave] = useState(false);
  const [isSpin, setSpinner] = useState(false);
  const [metaError, setMetaError] = useState(false);

  const [success, setSuccess] = useState(false);

  const [modal, setModal] = useState(false);

  const [web, setWeb] = useState({
    website_category: 64,
    website_url: "",
    ad_type: "",
    native_check: 0,
    ad_name: "",
    erotic_ad: 0,
    alert_ad: 0,
  });

  const [err, setErr] = useState(null);
  const [cntry, setCntry] = useState(false);

  const [gridType, setGridType] = useState(0);
  const [adSize, setAdSize] = useState(0);

  const [tooltipOpen, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!tooltipOpen);
  };

  const [tooltipOpen2, setOpen2] = useState(false);
  const toggle2 = () => {
    setOpen2(!tooltipOpen2);
  };

  const [tooltipOpen3, setOpen3] = useState(false);
  const toggle3 = () => {
    setOpen3(!tooltipOpen3);
  };

  const [loading, setLoading] = useState(false);

  const [cats, setCats] = useState(null);

  const getAllCats = async () => {
    const res = await getCategoryList();
    // console.log(res);
    setCats(res);
  };
  

  const submitAd = async () => {
      setLoading(true);
      const res = await checkWebsite({'site_url':web.website_url});
      console.log(res)
      if(res.code == 101){
        setLoading(false);
        toast.error(res.message, {
          position: "top-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      }
      else{
        setTimeout(function() { 
          setModal(true); 
          setLoading(false); 
        }, 1000); 
      }
  };

  const [dsUrl, setDsUrl] = useState("");
  const [webPubCode, setWebPubCode] = useState("");

  const updateWallet = async() => {
    setLoading(true);

    let uid = localStorage.getItem("uid");
    let res = await getWallet(uid);
    if (res.code == 200) {
    
      ctx.dispatch({
        type: "wlt",
        wlt: res.data,
      });
    } 
    setLoading(false);
  }
  
  const generateRandomString = () => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let randomString = '';

      for (let i = 0; i <= 25; i++) {
          const randomIndex = Math.floor(Math.random() * characters.length);
          randomString += characters.charAt(randomIndex);
      }
      setWebPubCode(randomString);
      // return randomString;
  }
  const verifyMeta = async() => {
    setLoading(true);
    let res = await checkMeta({'url':web.website_url,'code':webPubCode});
    if (res.code == 200) {
      let uid = localStorage.getItem("uid");
      let res = await adWebsite({
        uid: uid,
        website_category: web.website_category,
        site_url: web.website_url,
        code: webPubCode,
        ad_name: web.ad_name,
        ad_type: web.ad_type,
        grid_type: gridType,
        ad_size: adSize,
        erotic_ads: web.erotic_ad,
        alert_ads: web.alert_ad,
      });
      if (res.code == 200) {
        setSuccess(true);
      } else if(res.code == 102){
        setMetaError(true)
      } else {
        toast.error(res.message, {
          position: "top-right",
          autoClose: true,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
      }
      
    }
    else{
      setModal(false);
      toast.error("Your Website ownership is not verified!", {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });
    } 
    setLoading(false);
  }


  useEffect(() => {
    const uid = localStorage.getItem("uid");
    getAllCats();
    updateWallet();
    console.log(ctx.sts);
    generateRandomString();
  }, []);

  return (
    <React.Fragment>
      <Head title="Add New Website" />
      {success == true ? (
        <Content>
          <SuccessWeb title="Website" className="mt-5" />
        </Content>
      ) : (
        <Content>
          <Loader visible={loading} />
          <Block size="lg">
            <BlockHead size="lg" wide="sm">
              <BlockHeadContent>
                <BackTo link="/websites" icon="arrow-left">
                  Websites
                </BackTo>
                <BlockTitle page>Add New Website</BlockTitle>
                <BlockDes className="text-soft">
                  Here you can add a new website.
                </BlockDes>
              </BlockHeadContent>
            </BlockHead>
            <PreviewCard>
              <Row className="gy-3">
                <Col md="6">
                  <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
                    <Form onSubmit={handleSubmit(submitAd)}>
                      <Row className="gy-3">
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-label mt-3"
                              htmlFor="fw-token-address"
                            >
                              Website URL{" "}
                              <Icon
                                name="info-fill"
                                id="adurl"
                                className="text-primary"
                              />
                            </label>
                            <Tooltip
                              placement="auto"
                              isOpen={tooltipOpen}
                              target="adurl"
                              toggle={toggle}
                            >
                              domain.com or sub.domain.com.
                            </Tooltip>
                            <div className="form-control-wrap mb-3">
                              <input
                                type="text"
                                className="form-control"
                                name="target_url"
                                onChange={(e) => {
                                  // (getValues('target_url') === '') ? setTxt({...txt, target_url:stitle }) : setTxt({...txt, target_url:getValues('target_url') })
                                  // setDsUrl(e.target.value);
                                  setWeb({
                                    ...web,
                                    website_url: e.target.value,
                                  });
                                  setErr(null);
                                }}
                                ref={register({
                                  required: true,
                                  pattern:
                                    /^([a-zA-Z0-9]([-a-zA-Z0-9]{0,61}[a-zA-Z0-9])?\.)?([a-zA-Z0-9]{1,2}([-a-zA-Z0-9]{0,252}[a-zA-Z0-9])?)\.([a-zA-Z]{2,63})$/,
                                })}
                                placeholder="e.g. - domain.com or sub.domain.com"
                              />
                              {errors.target_url && (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: 13 }}
                                >
                                  {errors.target_url.type === "pattern" ? "Follow the given format- example.com or sub-domain.example.com":"Please enter Website URL"}
                                </span>
                              )}
                              {err != null && err.site_url ? (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: 13 }}
                                >
                                  {err.site_url}
                                </span>
                              ) : (
                                ""
                              )}
                            </div>

                            <label
                              className="form-label"
                              htmlFor="fw-token-address"
                            >
                              Website Category{" "}
                              <Icon
                                name="info-fill"
                                id="adcat"
                                className="text-primary"
                              />
                            </label>
                            <Tooltip
                              placement="auto"
                              isOpen={tooltipOpen2}
                              target="adcat"
                              toggle={toggle2}
                            >
                              Your website category.
                            </Tooltip>
                            <div className="form-control-wrap">
                              <select
                                className="form-control"
                                name="website_category"
                                ref={register({ required: true })}
                                onChange={(e) => {
                                  var index = e.target.selectedIndex;
                                  if (
                                    e.target[index].text == "Select Category"
                                  ) {
                                    setWeb({ ...web, website_category: "" });
                                  } else {
                                    setWeb({
                                      ...web,
                                      website_category: e.target[index].value,
                                    });
                                  }
                                }}
                              >
                                <option value="64" key={64}>
                                  All Category
                                </option>
                                {cats !== null
                                  ? cats.map(
                                      (item) => {
                                        let sel =
                                          web.website_category == item.label
                                            ? true
                                            : false;
                                        return (
                                          <option
                                            key={item.value}
                                            defaultValue={sel}
                                            value={item.value}
                                          >
                                            {item.label}
                                          </option>
                                        );
                                      }
                                      // <option key={key} {txt.website_category === item.label && 'selected'}></option>
                                      // <option key={key}>{item.label}</option>
                                    )
                                  : ""}
                              </select>
                              {errors.website_category && (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: 13 }}
                                >
                                  Please select a website category
                                </span>
                              )}
                            </div>

                            <label
                              className="form-label mt-3"
                              htmlFor="fw-token-address"
                            >
                              Ad Unit{" "}
                              <Icon
                                name="info-fill"
                                id="aduni"
                                className="text-primary"
                              />
                            </label>
                            <Tooltip
                              placement="auto"
                              isOpen={tooltipOpen3}
                              target="aduni"
                              toggle={toggle3}
                            >
                              Your ad unit name.
                            </Tooltip>
                            <div className="form-control-wrap mb-3">
                              <input
                                type="text"
                                className="form-control"
                                name="ad_name"
                                maxLength={50}
                                onChange={(e) => {
                                  setWeb({ ...web, ad_name: e.target.value });

                                  if (e.target.value.trim().length == 0) {
                                    setValue("ad_name", e.target.value.trim(), {
                                      shouldValidate: true,
                                    });
                                  }
                                }}
                                ref={register({ required: true })}
                                placeholder="Ad unit name"
                              />
                              {errors.ad_name && (
                                <span
                                  className="text-danger"
                                  style={{ fontSize: 13 }}
                                >
                                  Please enter ad unit name
                                </span>
                              )}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="gy-3">
                        <Col md="12">
                          <Row className="gy-4">
                            <Col xl="12">
                              <div className="preview-block">
                                <label
                                  className="form-label"
                                  htmlFor="fw-token-address"
                                >
                                  Available Ad Unit
                                </label>
                                <div>
                                  {errors.adtype && (
                                    <span
                                      className="text-danger"
                                      style={{ fontSize: 13 }}
                                    >
                                      Please select a ad type
                                    </span>
                                  )}
                                </div>

                                <div className="g-4 align-center flex-wrap">
                                  <div className="g">
                                    <div className="custom-control custom-control-sm custom-radio">
                                      <input
                                        type="radio"
                                        className="custom-control-input form-control"
                                        name="adtype"
                                        id="adType1"
                                        onClick={(e) => {
                                          setWeb({
                                            ...web,
                                            ad_type: "text",
                                            native_check: 0,
                                          });
                                          setGridType(0);
                                        }}
                                        ref={register({ required: true })}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="adType1"
                                      >
                                        Text Ad
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xl="12">
                              <div className="preview-block">
                                <div className="g-4 align-center flex-wrap">
                                  <div className="g">
                                    <div className="custom-control custom-control-sm custom-radio">
                                      <input
                                        type="radio"
                                        className="custom-control-input form-control"
                                        name="adtype"
                                        id="adType2"
                                        onClick={(e) => {
                                          setWeb({
                                            ...web,
                                            ad_type: "banner",
                                            native_check: 0,
                                          });
                                          setGridType(0);
                                        }}
                                        ref={register({ required: true })}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="adType2"
                                      >
                                        Banner Ad
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>

                            {web.ad_type == "banner" && (
                              <Col xl="12">
                                <div className="preview-block">
                                  <label
                                    className="form-label"
                                    htmlFor="fw-token-address"
                                  >
                                    Select Ad Size
                                  </label>
                                  <div className="mb-1">
                                    {errors.adsize && (
                                      <span
                                        className="text-danger"
                                        style={{ fontSize: 13 }}
                                      >
                                        Please select a Image size for banner ad
                                      </span>
                                    )}
                                  </div>
                                  <Row className="gy-4">
                                    <Col md="3" lg="2">
                                      <div className="custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="size1"
                                          value="Size1"
                                          onClick={(e) => {
                                            setAdSize(1);
                                          }}
                                          name="adsize"
                                          checked={adSize == 1 ? true : false}
                                          ref={register({ required: true })}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="size1"
                                          style={{
                                            display: "block",
                                            padding: 2,
                                            textAlign: "center",
                                          }}
                                        >
                                          <div>
                                            <img src={banner1} alt="" />
                                          </div>
                                          <div>
                                            <label
                                              className="form-label"
                                              htmlFor="fw-token-address"
                                              style={{
                                                backgroundColor: "#3b82f6",
                                                color: "white",
                                                padding: "4px 0",
                                                display: "block",
                                              }}
                                            >
                                              250X250
                                            </label>
                                          </div>
                                        </label>
                                        <br />
                                      </div>
                                    </Col>
                                    <Col md="3" lg="2">
                                      <div className="custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="size2"
                                          value="Size2"
                                          onClick={(e) => {
                                            setAdSize(2);
                                          }}
                                          name="adsize"
                                          checked={adSize == 2 ? true : false}
                                          ref={register({ required: true })}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="size2"
                                          style={{
                                            display: "block",
                                            padding: 2,
                                            textAlign: "center",
                                          }}
                                        >
                                          <div>
                                            <img src={banner2} alt="" />
                                          </div>
                                          <div>
                                            <label
                                              className="form-label"
                                              htmlFor="fw-token-address"
                                              style={{
                                                backgroundColor: "#3b82f6",
                                                color: "white",
                                                padding: "4px 0",
                                                display: "block",
                                              }}
                                            >
                                              300X250
                                            </label>
                                          </div>
                                        </label>
                                        <br />
                                      </div>
                                    </Col>
                                    <Col md="3" lg="2">
                                      <div className="custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="size3"
                                          value="Size3"
                                          onClick={(e) => {
                                            setAdSize(3);
                                          }}
                                          name="adsize"
                                          checked={adSize == 3 ? true : false}
                                          ref={register({ required: true })}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="size3"
                                          style={{
                                            display: "block",
                                            padding: 2,
                                            textAlign: "center",
                                          }}
                                        >
                                          <div>
                                            <img src={banner3} alt="" />
                                          </div>
                                          <div>
                                            <label
                                              className="form-label"
                                              htmlFor="fw-token-address"
                                              style={{
                                                backgroundColor: "#3b82f6",
                                                color: "white",
                                                padding: "4px 0",
                                                display: "block",
                                              }}
                                            >
                                              468X60
                                            </label>
                                          </div>
                                        </label>
                                        <br />
                                      </div>
                                    </Col>
                                    <Col md="3" lg="2">
                                      <div className="custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="size4"
                                          value="Size4"
                                          onClick={(e) => {
                                            setAdSize(5);
                                          }}
                                          name="adsize"
                                          checked={adSize == 5 ? true : false}
                                          ref={register({ required: true })}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="size4"
                                          style={{
                                            display: "block",
                                            padding: 2,
                                            textAlign: "center",
                                          }}
                                        >
                                          <div>
                                            <img src={banner4} alt="" />
                                          </div>
                                          <div>
                                            <label
                                              className="form-label"
                                              htmlFor="fw-token-address"
                                              style={{
                                                backgroundColor: "#3b82f6",
                                                color: "white",
                                                padding: "4px 0",
                                                display: "block",
                                              }}
                                            >
                                              728X90
                                            </label>
                                          </div>
                                        </label>
                                        <br />
                                      </div>
                                    </Col>
                                    <Col md="3" lg="2">
                                      <div className="custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="size5"
                                          value="Size5"
                                          onClick={(e) => {
                                            setAdSize(4);
                                          }}
                                          name="adsize"
                                          checked={adSize == 4 ? true : false}
                                          ref={register({ required: true })}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="size5"
                                          style={{
                                            display: "block",
                                            padding: 2,
                                            textAlign: "center",
                                          }}
                                        >
                                          <div>
                                            <img src={banner5} alt="" />
                                          </div>
                                          <div>
                                            <label
                                              className="form-label"
                                              htmlFor="fw-token-address"
                                              style={{
                                                backgroundColor: "#3b82f6",
                                                color: "white",
                                                padding: "4px 0",
                                                display: "block",
                                              }}
                                            >
                                              160X600
                                            </label>
                                          </div>
                                        </label>
                                        <br />
                                      </div>
                                    </Col>
                                    <Col md="3" lg="2">
                                      <div className="custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="size6"
                                          value="Size6"
                                          onClick={(e) => {
                                            setAdSize(6);
                                          }}
                                          name="adsize"
                                          checked={adSize == 6 ? true : false}
                                          ref={register({ required: true })}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="size6"
                                          style={{
                                            display: "block",
                                            padding: 2,
                                            textAlign: "center",
                                          }}
                                        >
                                          <div>
                                            <img src={banner6} alt="" />
                                          </div>
                                          <div>
                                            <label
                                              className="form-label"
                                              htmlFor="fw-token-address"
                                              style={{
                                                backgroundColor: "#3b82f6",
                                                color: "white",
                                                padding: "4px 0",
                                                display: "block",
                                              }}
                                            >
                                              300X600
                                            </label>
                                          </div>
                                        </label>
                                        <br />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            )}

                            <Col xl="12">
                              <div className="preview-block">
                                <div className="g-4 align-center flex-wrap">
                                  <div className="g">
                                    <div className="custom-control custom-control-sm custom-radio">
                                      <input
                                        type="radio"
                                        className="custom-control-input form-control"
                                        name="adtype"
                                        id="adType3"
                                        onClick={(e) => {
                                          setWeb({ ...web, ad_type: "native" });
                                          setGridType(0);
                                        }}
                                        ref={register({ required: true })}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="adType3"
                                      >
                                        Native Ad
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            {web.ad_type == "native" && (
                              <Col xl="12">
                                <div className="custom-control custom-control-sm custom-checkbox mr-3">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input form-control"
                                    checked={
                                      web.native_check == 1 ? true : false
                                    }
                                    id="nativeCheck1"
                                    name="grid_align"
                                    onChange={(e) => {
                                      let val = e.target.checked ? 1 : 0;
                                      setWeb({ ...web, native_check: val });
                                    }}
                                    ref={register({ required: true })}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="nativeCheck1"
                                  >
                                    Horizontal
                                  </label>
                                </div>
                                <div className="custom-control custom-control-sm custom-checkbox ">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input form-control"
                                    checked={
                                      web.native_check == 2 ? true : false
                                    }
                                    id="nativeCheck2"
                                    name="grid_align"
                                    onChange={(e) => {
                                      let val = e.target.checked ? 2 : 0;
                                      setWeb({ ...web, native_check: val });
                                    }}
                                    ref={register({ required: true })}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="nativeCheck2"
                                  >
                                    Vertical
                                  </label>
                                </div>
                                <div className="mt-1">
                                  {errors.grid_align && (
                                    <span
                                      className="text-danger"
                                      style={{ fontSize: 13 }}
                                    >
                                      Please select a grid alignment for native
                                      ad
                                    </span>
                                  )}
                                </div>
                              </Col>
                            )}
                            {web.native_check == 1 && (
                              <Col xl="12">
                                <div className="preview-block">
                                  <label
                                    className="form-label"
                                    htmlFor="fw-token-address"
                                  >
                                    Select Horizontal Grid Type
                                  </label>
                                  <div className="mb-1">
                                    {errors.gridtype && (
                                      <span
                                        className="text-danger"
                                        style={{ fontSize: 13 }}
                                      >
                                        Please select a grid type for native ad
                                      </span>
                                    )}
                                  </div>
                                  <Row className="gy-4">
                                    <Col md="3" lg="3">
                                      <div className="custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="grid1"
                                          value="Grid1"
                                          onClick={(e) => {
                                            setGridType(1);
                                          }}
                                          name="gridtype"
                                          checked={gridType == 1 ? true : false}
                                          ref={register({ required: true })}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="grid1"
                                          style={{
                                            display: "block",
                                            padding: 5,
                                            textAlign: "center",
                                          }}
                                        >
                                          <div>
                                            <img src={grid1} alt="" />
                                          </div>
                                          <div>
                                            <label
                                              className="form-label"
                                              htmlFor="fw-token-address"
                                            >
                                              Grid Type 1
                                            </label>
                                          </div>
                                        </label>
                                        <br />
                                      </div>
                                    </Col>
                                    <Col md="3" lg="3">
                                      <div className="custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="grid2"
                                          value="Grid2"
                                          name="gridtype"
                                          onClick={(e) => {
                                            setGridType(2);
                                          }}
                                          checked={gridType == 2 ? true : false}
                                          ref={register({ required: true })}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="grid2"
                                          style={{
                                            display: "block",
                                            padding: 5,
                                            textAlign: "center",
                                          }}
                                        >
                                          <div>
                                            <img src={grid2} alt="" />
                                          </div>
                                          <div>
                                            <label
                                              className="form-label"
                                              htmlFor="fw-token-address"
                                            >
                                              Grid Type 2
                                            </label>
                                          </div>
                                        </label>
                                        <br />
                                      </div>
                                    </Col>
                                    <Col md="3" lg="3">
                                      <div className="custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="grid3"
                                          value="Grid3"
                                          name="gridtype"
                                          onClick={(e) => {
                                            setGridType(3);
                                          }}
                                          checked={gridType == 3 ? true : false}
                                          ref={register({ required: true })}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="grid3"
                                          style={{
                                            display: "block",
                                            padding: 5,
                                            textAlign: "center",
                                          }}
                                        >
                                          <div>
                                            <img src={grid3} alt="" />
                                          </div>
                                          <div>
                                            <label
                                              className="form-label"
                                              htmlFor="fw-token-address"
                                            >
                                              Grid Type 3
                                            </label>
                                          </div>
                                        </label>
                                        <br />
                                      </div>
                                    </Col>
                                    <Col md="3" lg="3">
                                      <div className="custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="tablet"
                                          value="Tablet"
                                          name="gridtype"
                                          onClick={(e) => {
                                            setGridType(4);
                                          }}
                                          checked={gridType == 4 ? true : false}
                                          ref={register({ required: true })}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="tablet"
                                          style={{
                                            display: "block",
                                            padding: 5,
                                            textAlign: "center",
                                          }}
                                        >
                                          <div>
                                            <img src={grid4} alt="" />
                                          </div>
                                          <div>
                                            <label
                                              className="form-label"
                                              htmlFor="fw-token-address"
                                            >
                                              Grid Type 4
                                            </label>
                                          </div>
                                        </label>
                                        <br />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            )}
                            {web.native_check == 2 && (
                              <Col xl="12">
                                <div className="preview-block">
                                  <label
                                    className="form-label"
                                    htmlFor="fw-token-address"
                                  >
                                    Select Vertical Grid Type
                                  </label>
                                  <div className="mb-1">
                                    {errors.gridtype && (
                                      <span
                                        className="text-danger"
                                        style={{ fontSize: 13 }}
                                      >
                                        Please select a grid type for native ad
                                      </span>
                                    )}
                                  </div>
                                  <Row className="gy-4">
                                    <Col md="3" lg="3">
                                      <div className="custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="grid1"
                                          value="Grid1"
                                          onClick={(e) => {
                                            setGridType(5);
                                          }}
                                          name="gridtype"
                                          checked={gridType == 5 ? true : false}
                                          ref={register({ required: true })}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="grid1"
                                          style={{
                                            display: "block",
                                            padding: 5,
                                            textAlign: "center",
                                          }}
                                        >
                                          <div>
                                            <img src={grid5} alt="" />
                                          </div>
                                          <div>
                                            <label
                                              className="form-label"
                                              htmlFor="fw-token-address"
                                            >
                                              Grid Type 1
                                            </label>
                                          </div>
                                        </label>
                                        <br />
                                      </div>
                                    </Col>
                                    <Col md="3" lg="3">
                                      <div className="custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="grid2"
                                          value="Grid2"
                                          name="gridtype"
                                          onClick={(e) => {
                                            setGridType(6);
                                          }}
                                          checked={gridType == 6 ? true : false}
                                          ref={register({ required: true })}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="grid2"
                                          style={{
                                            display: "block",
                                            padding: 5,
                                            textAlign: "center",
                                          }}
                                        >
                                          <div>
                                            <img src={grid6} alt="" />
                                          </div>
                                          <div>
                                            <label
                                              className="form-label"
                                              htmlFor="fw-token-address"
                                            >
                                              Grid Type 2
                                            </label>
                                          </div>
                                        </label>
                                        <br />
                                      </div>
                                    </Col>
                                    <Col md="3" lg="3">
                                      <div className="custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="grid3"
                                          value="Grid3"
                                          name="gridtype"
                                          onClick={(e) => {
                                            setGridType(7);
                                          }}
                                          checked={gridType == 7 ? true : false}
                                          ref={register({ required: true })}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="grid3"
                                          style={{
                                            display: "block",
                                            padding: 5,
                                            textAlign: "center",
                                          }}
                                        >
                                          <div>
                                            <img src={grid7} alt="" />
                                          </div>
                                          <div>
                                            <label
                                              className="form-label"
                                              htmlFor="fw-token-address"
                                            >
                                              Grid Type 3
                                            </label>
                                          </div>
                                        </label>
                                        <br />
                                      </div>
                                    </Col>
                                    <Col md="3" lg="3">
                                      <div className="custom-control custom-checkbox custom-control-pro custom-control-pro-icon no-control checked">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id="tablet"
                                          value="Tablet"
                                          name="gridtype"
                                          onClick={(e) => {
                                            setGridType(8);
                                          }}
                                          checked={gridType == 8 ? true : false}
                                          ref={register({ required: true })}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="tablet"
                                          style={{
                                            display: "block",
                                            padding: 5,
                                            textAlign: "center",
                                          }}
                                        >
                                          <div>
                                            <img src={grid8} alt="" />
                                          </div>
                                          <div>
                                            <label
                                              className="form-label"
                                              htmlFor="fw-token-address"
                                            >
                                              Grid Type 4
                                            </label>
                                          </div>
                                        </label>
                                        <br />
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </Col>
                            )}
                            <Col xl="12">
                              <div className="preview-block">
                                <div className="g-4 align-center flex-wrap">
                                  <div className="g">
                                    <div className="custom-control custom-control-sm custom-radio">
                                      <input
                                        type="radio"
                                        className="custom-control-input form-control"
                                        name="adtype"
                                        id="adType4"
                                        onClick={(e) => {
                                          setWeb({
                                            ...web,
                                            ad_type: "social",
                                            native_check: 0,
                                          });
                                          setGridType(0);
                                        }}
                                        ref={register({ required: true })}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="adType4"
                                      >
                                        In-Page Push Ad
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xl="12">
                              <div className="preview-block">
                                <div className="g-4 align-center flex-wrap">
                                  <div className="g">
                                    <div className="custom-control custom-control-sm custom-radio">
                                      <input
                                        type="radio"
                                        className="custom-control-input form-control"
                                        name="adtype"
                                        id="adType5"
                                        onClick={(e) => {
                                          setWeb({
                                            ...web,
                                            ad_type: "popunder",
                                            native_check: 0,
                                          });
                                          setGridType(0);
                                        }}
                                        ref={register({ required: true })}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="adType5"
                                      >
                                        Popunder Ad
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            {/* <Col xl="12">
                <p className="text-soft">
                  For large or small size of <code>.custom-radio</code>, use <code>.custom-control-{`lg|sm`}</code> with{" "}
                  <code>.custom-control</code> className.
                </p>
              </Col> */}
                            <Col xl="12">
                              <div className="form-group align-items-end align-contents-end">
                                <span className="preview-title overline-title">
                                  <span className="text-primary">Remove</span>{" "}
                                  Campaign with
                                </span>
                                <div className="form-control-wrap">
                                  <div className="g">
                                    {web.website_category != 63 && (
                                      <div className="custom-control custom-control-sm custom-checkbox mr-3">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input form-control"
                                          id="customCheck6"
                                          onChange={(e) => {
                                            let val = e.target.checked ? 1 : 0;
                                            setWeb({ ...web, erotic_ad: val });
                                          }}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="customCheck6"
                                        >
                                          Erotic Ads
                                        </label>
                                      </div>
                                    )}
                                    {web.website_category != 17 && (
                                      <div className="custom-control custom-control-sm custom-checkbox ">
                                        <input
                                          type="checkbox"
                                          className="custom-control-input form-control"
                                          id="customCheck7"
                                          onChange={(e) => {
                                            let val = e.target.checked ? 1 : 0;
                                            setWeb({ ...web, alert_ad: val });
                                          }}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="customCheck7"
                                        >
                                          Alert Ads
                                        </label>
                                      </div>
                                    )}
                                    <div>
                                      <small>
                                        Your CPM value will be higher if you
                                        allow more ads.
                                      </small>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        {/* <Col md="5" className="pt-5">
                      <div className="pt-5">
                        <div className="form-group align-items-end align-contents-end">
                          <span className="preview-title overline-title">
                            <span className="text-primary">Remove</span>{" "}
                            Campaign with
                          </span>
                          <div className="form-control-wrap">
                            <div className="g">
                              {web.website_category != 63 && (
                                <div className="custom-control custom-control-sm custom-checkbox mr-3">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input form-control"
                                    id="customCheck6"
                                    onChange={(e) => {
                                      let val = e.target.checked ? 1 : 0;
                                      setWeb({ ...web, erotic_ad: val });
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck6"
                                  >
                                    Erotic Ads
                                  </label>
                                </div>
                              )}
                              {web.website_category != 17 && (
                                <div className="custom-control custom-control-sm custom-checkbox ">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input form-control"
                                    id="customCheck7"
                                    onChange={(e) => {
                                      let val = e.target.checked ? 1 : 0;
                                      setWeb({ ...web, alert_ad: val });
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="customCheck7"
                                  >
                                    Alert Ads
                                  </label>
                                </div>
                              )}
                              <div>
                                <small>
                                  Your CPM value will be higher if you allow
                                  more ads.
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col> */}
                      </Row>
                      {/* <hr className="mb-0" /> */}
                      <div className="actions clearfix">
                        <ul>
                          <li>
                            <Button
                              color="primary"
                              type="subit"
                            >
                              {fsave == true ? (
                                <span>
                                  <Spinner size="sm" /> &nbsp; Saving
                                </span>
                              ) : (
                                "Submit"
                              )}
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </Form>
                  </div>
                </Col>
                <Col md="6">
                  <PreviewCard
                    className="card-bordered-new m-4"
                    bodyClass="card-inner-lg"
                  >
                    <BlockContent>
                      <BlockTitle tag="h3" className="text-left">
                        Terms & Conditions of Website Approval
                      </BlockTitle>
                      <BlockTitle tag="h4" className="text-left mb-3">
                      Monetization Eligibility Criteria
                      </BlockTitle>
                      <BlockDes>
                        {/* <h1>Monetization Eligibility Criteria.</h1> */}
                        <ul className="orderList">
                          {/* <li>Monetization Eligibility Criteria.</li> */}
                          <li>
                            <b>Minimum Traffic:</b> Ensure your website attracts a
                            minimum of 1,000 monthly visits to be eligible for
                            publisher approval.
                          </li>
                          <li>
                            <b>SSL Certificate:</b> A secure browsing experience is
                            essential. Make sure your website is equipped with
                            an <b>SSL certificate (https)</b> for a successful publisher
                            application.
                          </li>
                          <li>
                            Websites/publishers must adhere to the <b>minimum
                            requirement criteria</b> to be monetized. Additionally,
                            your website must also comply with all of our Publisher{" "}
                            <a
                              href="https://www.7searchppc.com/terms-conditions#publisher"
                              target="_blank"
                              className="font-weight-bold"
                            >
                              Terms and Policy
                            </a>
                            .
                          </li>
                        </ul>
                      </BlockDes>
                    </BlockContent>
                  </PreviewCard>
                </Col>
              </Row>
            </PreviewCard>
          </Block>
          <Modal isOpen={modal} className="modal-dialog-centered" size="lg" wrapClassName="customBg">
            <ModalHeader className="sm-title">Verify Website Ownership
                <a href="#cancel" onClick={(ev) => {  ev.preventDefault(); setModal(false)   }} className="close" > <Icon name="cross-sm"></Icon> </a>
              { (web.website_url) && <p>{web.website_url}</p>}
            </ModalHeader>
            <ModalBody>
               <Container>
                <Col md="12">
                  <BlockContent  className={"p-3"}>
                    <BlockTitle tag="h4" className="text-left">
                      Meta Tag Code
                    </BlockTitle>
                    <BlockDes>
                      <p className="metaNoteText"> Paste the following code in the <code>{"<head></head>"}</code> section of your website and verify website ownership.</p>
                      {
                        (metaError) &&
                        <span className="text-danger">Your ownership is not verified please add below Meta tag in given Website URL!</span>
                      }  
                      
                      { webPubCode && 
                      <CodeBlock language="jsx" title="COPY CODE">
                        {`<meta name="7searchppc" content="${webPubCode}"/>`}
                      </CodeBlock>
                      }
                      <h5 className="mt-3">Verify to continue</h5>
                      <p> To stay verified, don't remove the meta tag, even after verification succeeds.</p>
                      <Button onClick={(ev) => { ev.preventDefault();  verifyMeta()}} className="btn-block mt-2" color="primary"> VERIFY </Button>
                    </BlockDes>
                  </BlockContent>
                </Col>
              </Container>
            </ModalBody>
          </Modal>
        </Content>
      )}
      
      <ToastContainer />
    </React.Fragment>
  );
}
