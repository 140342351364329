
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { updateNotiToken } from './app/api';

const firebaseConfig = {
  apiKey: "AIzaSyB6iqHlmjly_sXB7h1epmBkf6_0eEcNVws",
  authDomain: "searchpub-8343e.firebaseapp.com",
  projectId: "searchpub-8343e",
  storageBucket: "searchpub-8343e.appspot.com",
  messagingSenderId: "375968185611",
  appId: "1:375968185611:web:46a1ad2b13895f5890f60e",
  measurementId: "G-CBE7D4T768"
};

  
//   const [isTokenFound, setTokenFound] = useState(false)
  const apps = initializeApp(firebaseConfig);
  const analytics = getAnalytics(apps);
  const messaging = getMessaging(apps);
  export { messaging,analytics , onMessage };
const saveToken = async (token) => {
  let res = await updateNotiToken(token);
}

export const Sendrequest = () => {
    console.log("Requesting User Permission......");
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        console.log("Notification User Permission Granted.");
  
        return getToken(messaging, { vapidKey: `BMaKKD2UREWF8OTonshf5JFzzUN_DchrYdyZJ8s_8-7XFhMo5Ic0QEmM71cOpnWOBMqgHiPeVtxjKvFOsBjEHDY` })
          .then((currentToken) => {
            if (currentToken) {
              saveToken(currentToken)
              console.log('Client Token: ', currentToken);
              
            } else {
              
              console.log('Failed to generate the registration token.');
            }
          })
          .catch((err) => {
            console.log('An error occurred when requesting to receive the token.', err);
          });
      } else {
        console.log("User Permission Denied.");
      }
    });
  }

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});
