import React, { useEffect } from 'react'
import { useParams } from 'react-router';
import { validateToken } from '../../app/api';
import { Block } from '../../components/Component';
import Head from '../../layout/head/Head';
import PageContainer from '../../layout/page-container/PageContainer';

const AutoLog = () => {
    let {token, type} = useParams();
    const getBrowserName = () => {
        var nAgt = navigator.userAgent;
        let browserName = "";
    
        if (nAgt.indexOf("Opera") != -1) {
          browserName = "Opera";
        } else if (nAgt.indexOf("MSIE") != -1) {
          browserName = "Microsoft Internet Explorer";
        } else if (nAgt.indexOf("Edg") != -1) {
          browserName = "Microsoft Edge";
        } else if (nAgt.indexOf("Chrome") != -1) {
          browserName = "Chrome";
        } else if (nAgt.indexOf("Safari") != -1) {
          browserName = "Safari";
        } else if (nAgt.indexOf("Firefox") != -1) {
          browserName = "Firefox";
        } else {
          browserName = "--";
        }
    
        return browserName;
    };

    const tokenDecode = async (val) => {
        let str = window.atob(val);
        console.log(str);
        let vals = str.split('|');

        let utoken = vals[0]; 
        
        if(utoken.length > 0) {
            let browser = getBrowserName();
            let res = await validateToken(vals[1], utoken, type, browser, vals[2]);
          
            if(res.status == 1) {
                
                localStorage.setItem("accessToken", utoken);
                localStorage.setItem("uid", res.uid);
                localStorage.setItem("fname", res.fname);   
                localStorage.setItem("lname", res.lname);
                localStorage.setItem("email", res.email);
                localStorage.setItem("wlt", res.wallet);
                localStorage.setItem("earn", res.total_earning);
                localStorage.setItem("wid", res.total_withdrawl);
                localStorage.setItem("utype", res.account_type);
                localStorage.setItem("urole", res.urole);
                localStorage.setItem("authorization", res.authorization);
                localStorage.setItem("sup_log", res.sup_log);
                if(res.permissions == 0){
                  localStorage.setItem("perms", 9999);
                }else{
                  localStorage.setItem("perms", res.permissions);
                }
                localStorage.setItem("firstlogin", 1);
                
                setTimeout(() => {
                  window.history.pushState(
                    `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
                    "auth-login",
                    `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
                  );
                  window.location.reload();
                }, 1000);
            } 
        }

    }


    useEffect(()=>{
        tokenDecode(token);

    },[]);

    return (  
        <React.Fragment>
        <Head title="Auto Login" />
        <PageContainer>
            <Block className="nk-block-middle nk-auth-body  wide-xs">

            <h4 className='text-center text-primary'>Please wait...</h4>
            </Block>
        </PageContainer>
        </React.Fragment>
    )
      
    
}

export default AutoLog;