import React, { useState } from "react";
import { CountryDoughnut, SessionDoughnut } from "../../charts/analytics/AnalyticsCharts";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem } from "reactstrap";
import { Icon } from "../../../Component";

const ImpCountry = ({dvData}) => {
  const [sessionDevice, setSessionDevices] = useState("30");
//   const des = dvData ? parseFloat(dvData.desktop.percent) : 0;
//   const mob = dvData ? parseFloat(dvData.mobile.percent) : 0;
//   const tab = dvData ? parseFloat(dvData.tablet.percent) : 0;

  return (
    <React.Fragment>
      <div className="card-title-group">
        <div className="card-title card-title-sm">
          <h6 className="title">Countries-wise Impressions</h6>
        </div>
      </div>
      <div className="device-status my-auto">
        <div className="device-status-ck">
          {dvData ? 
          <CountryDoughnut className="analytics-doughnut" state={dvData} />
          : ''}
        </div>
      </div>
    </React.Fragment>
  );
};
export default ImpCountry;
