import React, { useContext, useState, useEffect } from 'react'
import {
    Row,
    Col,
    Tooltip,
    Button,
    FormGroup,
    Form,
    Spinner,
    Alert
  } from "reactstrap";
import { Icon, BlockHead, BlockHeadContent, BackTo, BlockTitle, BlockDes, PreviewCard, Block } from '../../components/Component';
import { adUnitInfo, updateAdUnit, getWallet } from '../../app/api';
import { useForm } from 'react-hook-form';
import { toast, ToastContainer } from "react-toastify";
import Head from '../../layout/head/Head';
import Content from '../../layout/content/Content';
import SuccessWeb from './SuccessWeb';
import { useParams } from 'react-router';

import AppContext from '../../context/AppContext';
import banner1 from "../../images/banner1.jpg";
import banner2 from "../../images/banner2.jpg";
import banner3 from "../../images/banner3.jpg";
import banner4 from "../../images/banner4.jpg";
import banner5 from "../../images/banner5.jpg";
import banner6 from "../../images/banner6.jpg";
import grid1 from '../../images/native-grid-1.png';
import grid2 from '../../images/native-grid-2.png';
import grid3 from '../../images/native-grid-3.png';
import grid4 from '../../images/native-grid-4.png';
import grid5 from '../../images/native-grid-5.png';
import grid6 from '../../images/native-grid-6.png';
import grid7 from '../../images/native-grid-7.png';
import grid8 from '../../images/native-grid-8.png';
import Loader from '../../app/Loader';


export default function UpdateAdUnit () {

  const {adid} = useParams();
  const ctx = useContext(AppContext);
  const showToast = (type) => {

    if(type == 1) {
      toast.success("Campaign saved successfully", {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        // closeButton: <CloseButton />,
      });
    } else {
      toast.error("Something went wrong", {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });
      
    }
  };

  const { errors, register, handleSubmit, setValue } = useForm();

    const [fsave, setFsave] = useState(false);
    const [success, setSuccess] = useState(false);
    
    const [tooltipOpen , setOpen] = useState(false);
    const toggle = () => { setOpen(!tooltipOpen) };

    
    const [adUnitSize, setAdUnitSize] = useState('');
    const [adGridSize, setAdGridSize] = useState('');
    const [selectedGridImage, setSelectedGridImage] = useState('');
    const [adType, setAdType] = useState('');
    const [adName, setAdName] = useState('');
    const [erAd, setErAd] = useState(false);
    const [erAd2, setErAd2] = useState(false);
    const [bannerImg, setBannerImg] = useState('');
    const [loading, setLoading] = useState(false);
    
    const [web, setWeb] = useState({
        ad_name:adName,
        erotic_ad:0,
        alert_ad:0,
    });

    const submitAd = async ( ) => {
      let uid = localStorage.getItem('uid');
      
      let res = await updateAdUnit({
        uid: uid,
        ad_code: adid,
        erotic_ads : web.erotic_ad,
        alert_ads : web.alert_ad,
        ad_name: (web.ad_name) ? web.ad_name : adName
      })
      if(res.code == 200) {
        setSuccess(true);
      }
    }

    
    const getAdName = async () => {
    let uid = localStorage.getItem('uid');    
        let res = await adUnitInfo({
            uid: uid,
            ad_code: adid
        });
        setAdName(res.data.ad_name);
        setErAd(res.data.erotic_ads == 1 ? true : false);
        setErAd2(res.data.alert_ads == 1 ? true : false);
        setWeb({...web, erotic_ad:res.data.erotic_ads, alert_ad: res.data.alert_ads});

        let ad_type = res.data.ad_type;
        setAdType(ad_type);
        
        if (ad_type === 'banner') {
          let imgType = res.data.ad_size;
          switch (imgType) {
            case (1):
              setAdUnitSize('250 X 250');
              setBannerImg(banner1);
              break;
            case (2):
              setAdUnitSize('300 X 250');
              setBannerImg(banner2);
              break;
            case (3):
              setAdUnitSize('468 X 60');
              setBannerImg(banner3);
              break;
            case (5):
              setAdUnitSize('728 X 90');
              setBannerImg(banner4);
              break;
            case (4):
              setAdUnitSize('160 X 600');
              setBannerImg(banner5);
              break;
            case (6):
              setAdUnitSize('300 X 600');
              setBannerImg(banner6);
              break;
            default:
              setAdUnitSize('Invalid Size');
              break;
          }
        }

        if (ad_type === 'native') {
          let grid_type = res.data.grid_type;
          setAdGridSize(grid_type);
          switch (grid_type) {
            case (1):
              setSelectedGridImage(grid1);
              break;
            case (2):
              setSelectedGridImage(grid2);
              break;
            case (3):
              setSelectedGridImage(grid3);
              break;
            case (4):
              setSelectedGridImage(grid4);
              break;
            case (5):
              setSelectedGridImage(grid5);
              break;
            case (6):
              setSelectedGridImage(grid6);
              break;
            case (7):
              setSelectedGridImage(grid7);
              break;
            case (8):
              setSelectedGridImage(grid8);
              break;
            default:
              setSelectedGridImage(0);
              break;
          }
        }

        // console.log('ad_type : '+ad_type);
    }
    const updateWallet = async() => {
      setLoading(true);
  
      let uid = localStorage.getItem("uid");
      let res = await getWallet(uid);
      if (res.code == 200) {
      
        ctx.dispatch({
          type: "wlt",
          wlt: res.data,
        });
      } 
      setLoading(false);
    }
    useEffect(() => {
      updateWallet()  
      getAdName()
    },[])
 
    return (
      <React.Fragment>  
      <Head title="Update Ad Unit" />
      {(success == true) ? 
        <Content >
          <SuccessWeb title="Ad Unit" type="update" className="mt-5" />
        </Content>
       :  
      <Content >
        <Loader visible={loading} />
        <BlockHead size="lg" wide="sm">
          <BlockHeadContent>
            <BackTo link="/websites" icon="arrow-left">
              Ad Unit {adid}
            </BackTo>
            <BlockTitle page>
              Update Ad Unit 
            </BlockTitle>
            <BlockDes className="text-soft">
                Here you can update ad unit.
            </BlockDes>
          </BlockHeadContent>
        </BlockHead>
        <Block size="lg">
        <PreviewCard>
          <div className="nk-wizard nk-wizard-simple is-alter wizard clearfix">
          <Form onSubmit={handleSubmit(submitAd)}>
            <Row className="gy-3">
              <Col md="6">
                  <Alert color='info'><Icon name="info" style={{fontSize:16}} /> &nbsp;You can update only ad unit name. Other fields are restricted.</Alert>
                <FormGroup>
                <label className="form-label mt-3" htmlFor="fw-token-address">
                  Ad Unit <Icon name="info-fill" id="adurl" className="text-primary" /> 
                  </label>
                  <Tooltip placement="auto"isOpen={tooltipOpen} target="adurl" toggle={toggle}>
                    Your ad unit name.
                  </Tooltip>
                  
                  <div className="form-control-wrap mb-3">
                  <input
                      type="text"
                      className="form-control"
                      name="ad_name"
                      maxLength={50}
                      onChange={( e )=> { 
                        setWeb({...web, ad_name:e.target.value })
                        if(e.target.value.trim().length == 0) {
                          setValue('ad_name', e.target.value.trim(), { shouldValidate: true })
                        }
                      }}
                      defaultValue={adName}
                    ref={register({ required: true})}
                    placeholder="Ad unit name"
                    />
                    {errors.ad_name && <span className="text-danger" style={{fontSize:13}}>Please enter ad unit name</span>}
                  </div> 
                </FormGroup>
              </Col>
            </Row>

            <Row className="gy-3">
              <Col md="6">
                {adType === 'banner' &&
                <FormGroup>
                  <label className="form-label mt-3" htmlFor="fw-token-address">
                    Ad Unit Size 
                  </label>
                    <label className="" htmlFor="size1" style={{'display':'block', padding:5, 'textAlign':'center', 'width':'130px'}} >
                      <div>
                        <img src={bannerImg} alt="" style={{'border-radius': '4px 4px 0px 0px', 'width':'130px'}} />
                      </div>
                      <div>
                        <label className="form-label" htmlFor="fw-token-address" style={{backgroundColor: "#3b82f6", color: "white", padding: "4px 0", display: "block", 'border-radius': '0px 0px 4px 4px'}} >
                          {adUnitSize}
                        </label>
                      </div>
                    </label>
                </FormGroup>
                }
                {adType === 'native' &&
                <FormGroup>
                  <label className="form-label mt-3" htmlFor="fw-token-address">
                    Ad Grid Size 
                  </label>
                  <div>
                    <label className="form-label" htmlFor="fw-token-address">
                      {adGridSize === 1 && 'Horizontal Grid Type'} 
                      {adGridSize === 2 && 'Horizontal Grid Type'} 
                      {adGridSize === 3 && 'Horizontal Grid Type'} 
                      {adGridSize === 4 && 'Horizontal Grid Type'}
                      {adGridSize === 5 && 'Vertical Grid Type'} 
                      {adGridSize === 6 && 'Vertical Grid Type'} 
                      {adGridSize === 7 && 'Vertical Grid Type'} 
                      {adGridSize === 8 && 'Vertical Grid Type'}  
                    </label>
                  </div>
                  <label className="" htmlFor="size1" style={{'display':'block', padding:5, 'textAlign':'center', 'width':'130px'}} >
                    <div>
                      <img src={selectedGridImage} alt="" />
                    </div>
                    <div>
                      <label className="form-label" htmlFor="fw-token-address" style={{backgroundColor: "#3b82f6", color: "white", padding: "4px 0", display: "block", 'border-radius': '4px 4px 4px 4px'}} >
                        {adGridSize === 1 && 'Grid Type ' + adGridSize} 
                        {adGridSize === 2 && 'Grid Type ' + adGridSize} 
                        {adGridSize === 3 && 'Grid Type ' + adGridSize} 
                        {adGridSize === 4 && 'Grid Type ' + adGridSize}
                        {adGridSize === 5 && 'Grid Type 1'} 
                        {adGridSize === 6 && 'Grid Type 2'} 
                        {adGridSize === 7 && 'Grid Type 3'} 
                        {adGridSize === 8 && 'Grid Type 4'}  
                      </label>
                    </div>
                  </label>
                </FormGroup>
                }
              </Col>
            </Row>

            <Row className="gy-3">
              <Col md="6" className='pl-3'>
                <div className='pt-3 pb-3'>
                <div className="form-group align-items-end align-contents-end">
                  <span className="preview-title overline-title"><span className='text-primary'>Remove</span> Campaign with</span> 
                  <div className="form-control-wrap">
                  <div className="g">
                  {ctx.sts.adult_cat != 63 &&
                      <div className="custom-control custom-control-sm custom-checkbox mr-2">
                        <input type="checkbox" className="custom-control-input form-control" id="customCheck7"
                            onChange={(e)=>{
                              let val = (e.target.checked) ? 1 : 0;
                              setWeb({...web, erotic_ad:val});
                              if(e.target.checked) {
                                setErAd(true);
                              } else {
                                setErAd(false);
                              }
                            }} 
                            checked={erAd}
                        />
                        <label className="custom-control-label" htmlFor="customCheck7">
                          Erotic Ads
                        </label>
                      </div>
                      }
                      {ctx.sts.adult_cat != 17 &&
                      <div className="custom-control custom-control-sm custom-checkbox">
                        <input type="checkbox" className="custom-control-input form-control" id="customCheck6"
                            onChange={(e)=>{
                              let val = (e.target.checked) ? 1 : 0;
                              setWeb({...web, alert_ad:val});
                              if(e.target.checked) {
                                setErAd2(true);
                              } else {
                                setErAd2(false);
                              }
                            }} 
                            checked={erAd2}
                        />
                        <label className="custom-control-label" htmlFor="customCheck6">
                          Alert Ads
                        </label>
                      </div>
                      }
                      <div>
                        <small>
                          Your CPM value will be higher if you allow more ads.
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </Col>
            </Row>
            <hr className='mb-0' />
            <div className="actions clearfix">
              <ul>
                <li>
                  <Button color="primary" type='subit' disabled={fsave}>
                  {(fsave == true) ? <span><Spinner size="sm" /> &nbsp; Updating</span> : 'Update'}
                  </Button>
                </li>
              </ul>
            </div>
            </Form>
            </div>
        </PreviewCard>
      </Block>
      </Content>
      }

    <ToastContainer />
  </React.Fragment>
      )
}