import React, {useEffect, useContext } from "react";
import Head from "../layout/head/Head";
import WelcomeImg from "../images/welcome.png"
import Content from "../layout/content/Content";
import { userPayStatus } from "../app/api";
import AppContext from "../context/AppContext";

const Welcome = () => {
  const apc = useContext(AppContext);
  const payStatus = async () => {
    const uid = localStorage.getItem('uid');
    const res = await userPayStatus({uid:uid});
    if(res.data) {
      apc.dispatch({
        type:'wlt',
        wlt: res.data.avalable_amt
      });
    }
  }
  
  useEffect(()=>{
    payStatus()
  },[])

  return (
    <React.Fragment>
      <Head title="Welcome"></Head>
      <Content>
          <div className="wide-xs mx-auto mt-5">
          <br/>
          <img className="nk-error-gfx" src={WelcomeImg} alt="Welcome"/>
            {/* <h3 className="text-center">Welcome To Publisher!</h3> */}
          </div>
      </Content>
    </React.Fragment>
  );
};

export default Welcome;
