import React, { useState, useEffect, useContext } from "react";
import Content from "../../layout/content/Content";
import UserProfileRegularPage from "./UserProfileRegular";
import UserProfileSettingPage from "./UserProfileSetting";
import UserProfileNotificationPage from "./UserProfileNotification";
import UserProfileActivityPage from "./UserProfileActivity";
import UserProfilesLog from "./UserProfileLog";
import { Route, Switch, Link } from "react-router-dom";
import {
  Icon,
  UserAvatar,
  Row,
  Col,
  Button,
  RSelect,
} from "../../components/Component";
import { findUpper, getDateStructured } from "../../utils/Utils";
import {
  Card,
  DropdownItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  FormGroup,
  Form,
  Spinner,
  ModalHeader,
} from "reactstrap";
import {
  getCountryList,
  getUserInfo,
  getWalletAmount,
  saveUserInfo,
  getMessengerList,
} from "../../app/api";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import AppContext from "../../context/AppContext";
import { useForm } from "react-hook-form";
import UserPayment from "./UserPayment";
import UserKYC from "./UserKYC";
import { kycInfo, delProfileRequest } from "../../app/api";
import Swal from "sweetalert2";
import Loader from "../../app/Loader";

const UserProfileLayout = () => {
  const apc = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [profileName, setProfileName] = useState("");
  const [wlt, setWlt] = useState(0);
  const [email, setEmail] = useState("");
  const [countries, setCountries] = useState(null);
  const [messengers, setMessengers] = useState([]);
  const [phnCode, setPhnCode] = useState("");

  const [modal, setModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const [nickDisabled, setNickDisabled] = useState(false);
  const [requestLoader, setRequestLoader] = useState(false);
  const [modalTab, setModalTab] = useState("1");
  const [delRequested, setDelRequested] = useState(2);
  const [totalChar, setTotalChar] = useState(300);
  const [userType, setUserType] = useState(0);
  const [getErrorServerSite, setErrorServerSite] = useState([]);
  const ctx = useContext(AppContext);
  const [deleteProfileFormData, setDeleteProfileFormData] = useState({
    message: "",
  });
  const [errDel, setErrDel] = useState({});

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    messenger_type: "",
    messenger_name: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    country: "",
    phonecode: "",
  });
  const [valid, setValid] = useState({});
  const [kycdata, setKycData] = useState({
    photo_verified: 0,
    photo_id_verified: 0,
  });

  const deleteProfileFormSubmit = async (e) => {
    setRequestLoader(true);
    e.preventDefault();
    if (requestLoader) {
      // code for not multiple click
      return;
    }
    var txt = deleteProfileFormData.message;
    let removespace = /^\s*$/;
    if (removespace.test(txt)) {
      setErrDel((prevErrors) => ({
        ...prevErrors,
        message: "Please enter a message",
      }));
      setRequestLoader(false);
      e.target.value = "";
      setRequestLoader(false);
    } else {
      if (!txt) {
        setErrDel((prevErrors) => ({
          ...prevErrors,
          message: "Please enter a message",
        }));
        setRequestLoader(false);
        return false;
      } else if (txt.length > 300) {
        setErrDel((prevErrors) => ({
          ...prevErrors,
          message: "Maximum character should be less than 300",
        }));
        setRequestLoader(false);
      } else {
        setErrDel((prevErrors) => ({
          ...prevErrors,
          message: "",
        }));
        const uid = localStorage.getItem("uid");
        const res = await delProfileRequest({
          uid: uid,
          del_remark: deleteProfileFormData.message,
        });

        setRequestLoader(false);
        if (res.code == 200) {
          toast.success("Delete Account Request has been sent successfully.", {
            position: "top-right",
            autoClose: true,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: false,
          });
          setDelRequested(2);
          setDelModal(1);
          setDeleteProfileFormData({ message: "" });
        }
      }
    }
  };

  const handleDeleteProfileFormChange = (e) => {
    const { name, value } = e.target;
    var txt = e.target.value;
    let removespace = /^\s*$/;
    if (removespace.test(txt)) {
      setErrDel((prevErrors) => ({
        ...prevErrors,
        message: "Please enter a message",
      }));
      setTotalChar(300);
      setDeleteProfileFormData((prevState) => ({
        ...prevState,
        [name]: "",
      }));
      e.target.value = "";
    } else {
      if (txt.length > 0) {
        setTotalChar(300 - txt.length);
        if (txt.length > 300) {
          setErrDel((prevErrors) => ({
            ...prevErrors,
            message: "Maximum charcater should be less than 300",
          }));
        } else {
          setErrDel((prevErrors) => ({
            ...prevErrors,
            message: "",
          }));
        }
      } else if (txt.length == 0) {
        setErrDel((prevErrors) => ({
          ...prevErrors,
          message: "Please enter a message",
        }));
      }
      setDeleteProfileFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const kycInfoData = async () => {
    let uid = localStorage.getItem("uid");

    let res = await kycInfo({
      uid: uid,
    });
    if (res.code == 200) {
      setKycData(res.data);
    }
  };

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  const {
    errors,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
  } = useForm();

  const infoToast = (type) => {
    if (type == "success") {
      toast.success("Profile Updated Successfully!", {
        position: "top-right",
        autoClose: true,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
      });
    }
    // if (type == "error") {
    //   toast.error("You cannot update phone number and messenger details until you enter your address!", {
    //     position: "top-right",
    //     autoClose: true,
    //     hideProgressBar: true,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: false,
    //   });
    // }
  };

  const setuserInfo = (data) => {
    if (data) {
      if (data.phonecode) {
        // setPhnCode(data.phonecode);
        setPhnCode("+" + data.phonecode); //suyash added
      }
      localStorage.setItem("fname", data.first_name.slice(0, 30));
      localStorage.setItem("lname", data.last_name.slice(0, 30));

      const email = localStorage.getItem("email");
      setProfileName(
        data.first_name.slice(0, 30) + " " + data.last_name.slice(0, 30)
      );
      let sts = apc.sts;

      // sts['first_name']
      apc.dispatch({
        type: "userinfo",
        payload: {
          username:
            data.first_name.slice(0, 30) + " " + data.last_name.slice(0, 30),
          email: email,
        },
      });
      setEmail(email);
    }
  };

  const setUserData = async () => {
    const uid = localStorage.getItem("uid");
    const uinfo = await getUserInfo(uid);
    // setProfileName(uinfo.first_name+' '+uinfo.last_name);
    if (uinfo.messenger_type === "None") {
      setNickDisabled(true);
    }
    if (uinfo.messenger_name === "None") {
      uinfo.messenger_name = "";
    }
    setFormData({
      ...uinfo,
      phonecode: "+" + uinfo.phonecode,
      first_name: uinfo.first_name.slice(0, 30),
      last_name: uinfo.last_name.slice(0, 30),
    });
    setDelRequested(uinfo.del_request);
    setUserType(uinfo.user_type);
    setWlt(uinfo.wallet);
    setuserInfo(uinfo);
    apc.dispatch({
      type: "wlt",
      wlt: uinfo.wallet,
    });
  };

  const submitForm = async (data) => {
    data["phonecode"] = phnCode;
    if (data["kyc_lock"] == "true") {
      // Added this code By Suyash
      // if (data["address_line2"] === null || data["address_line2"] === "") {
      //   infoToast("error");
      //   return;
      // }
      data["first_name"] = formData.first_name;
      data["last_name"] = formData.last_name;
      data["address_line1"] = formData.address_line1;
      data["address_line2"] = formData.address_line2;
      data["city"] = formData.city;
      data["state"] = formData.state;
      data["country"] = formData.country;
    } else {
      data["address_line2"] = formData.address_line2;
    }
    data["profile_lock"] = parseInt(data.profile_lock);
    if (nickDisabled === true) data["messenger_name"] = "None";
    if (data["messenger_type"] == "Skype") {
      const skypeIdRegex = /^live:.cid.[a-z0-9]{14,22}$/;
      if (!skypeIdRegex.test(data["messenger_name"])) {
        setError("messenger_name", {
          type: "manual",
          message: "Skype Name is Invalid",
        });
        return;
      }
    }

    setLoading(true);
    const uid = localStorage.getItem("uid");
    const res = await saveUserInfo(uid, data);
    if (res.code == 200) {
      setModal(false);
      infoToast("success");
      setuserInfo(res);
      data["email"] = res.email;
      setFormData(data);
      // setFormData({...formData, email:res.email});
    } else if (res.code == 100 && res.error.phone) {
      // setValid("phone", {
      //   type: "manual",
      //   message: "This Phone number has been already taken.",
      // });

      // code added by suyash
      setValid({
        ...valid,
        phone: "This Phone number has been already taken.",
      });
    }
    setLoading(false);
  };

  const setCountryData = async () => {
    let conts = await getCountryList();
    setCountries(conts);
  };

  const setMessengersData = async () => {
    let messengerList = await getMessengerList();
    setMessengers(messengerList.data);
  };

  const handleSelectChange = async (e) => {
    let index = e.target.options.selectedIndex;
    let pcode = "+" + e.target.options[index].getAttribute("phonecode");
    setPhnCode(pcode);
  };

  const reActiveAccount = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You request will be cancelled",
      icon: "warning",
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: "Yes, cancel it!",
      cancelButtonText: "No!",
      focusCancel: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setRequestLoader(true);
        const uid = localStorage.getItem("uid");
        const res = await delProfileRequest({
          uid: uid,
        });

        if (res.code == 200) {
          toast.success(
            "Your account deletion request has been successfully withdrawn.",
            {
              position: "top-right",
              autoClose: true,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: false,
            }
          );
          delRequested == 3 ? setDelRequested(1) : setDelRequested(0);
          setDeleteProfileFormData({ message: "" });
          setRequestLoader(false);
        }
      }
    });
  };

  const capitalizeEveryWord = (sentence) => {
    return sentence
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  useEffect(() => {
    viewChange();
    setUserData();
    setCountryData();
    kycInfoData();
    setMessengersData();

    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document
      .getElementsByClassName("nk-header")[0]
      .addEventListener("click", function () {
        updateSm(false);
      });
    setuserInfo();
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  return (
    <React.Fragment>
      <Content>
        <Card className="card-bordered">
          <div className="card-aside-wrap">
            <div
              className={`card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg ${
                sm ? "content-active" : ""
              }`}
            >
              <div className="card-inner-group">
                <div className="card-inner">
                  <div className="user-card">
                    <UserAvatar
                      text={findUpper(capitalizeEveryWord(profileName))}
                      theme="primary"
                    />
                    <div className="user-info" style={{ width: "75%" }}>
                      <span className="lead-text">{profileName}</span>
                      <span
                        className="sub-text"
                        style={{ overflowWrap: "break-word", display: "Block" }}
                      >
                        {localStorage.getItem("uid")}
                      </span>
                      <span
                        className="sub-text"
                        style={{ overflowWrap: "break-word", display: "Block" }}
                      >
                        {email}
                      </span>
                    </div>
                    {formData && formData.profile_lock == 0 && ctx.sts.perms.includes('448') || ctx.sts.perms.includes('9999') ? (
                      <div className="user-action">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            tag="a"
                            className="btn btn-icon btn-trigger mr-n2"
                          >
                            <Icon name="more-v"></Icon>
                          </DropdownToggle>
                          <DropdownMenu right>
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <DropdownItem
                                  tag="a"
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setModal(true);
                                  }}
                                >
                                  <Icon name="edit-fill"></Icon>
                                  <span>Update Profile</span>
                                </DropdownItem>
                              </li>
                            </ul>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="card-inner">
                  <div className="user-account-info py-0">
                    <h6 className="overline-title-alt"> Total Earnings</h6>
                    <div className="user-balance">${wlt}</div>
                    {/* <div className="user-balance-sub">
                      Locked{" "}
                      <span>
                        0.344939 <span className="currency currency-btc">BTC</span>
                      </span>
                    </div> */}
                  </div>
                </div>
                <div className="card-inner p-0">
                  <ul className="link-list-menu">
                  {ctx.sts.perms.includes('446') || ctx.sts.perms.includes('9999') ?
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/profile`}
                        className={
                          window.location.pathname ===
                          `${process.env.PUBLIC_URL}/profile`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="user-fill-c"></Icon>
                        <span>Personal Information</span>
                      </Link>
                    </li> : ''}
                    {/* <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile-notification`}
                        className={
                          window.location.pathname === `${process.env.PUBLIC_URL}/user-profile-notification`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="bell-fill"></Icon>
                        <span>Notification</span>
                      </Link>
                    </li> */}
                    {ctx.sts.perms.includes('450') || ctx.sts.perms.includes('9999') ?
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-payment-method`}
                        className={
                          window.location.pathname ===
                          `${process.env.PUBLIC_URL}/user-payment-method`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="cc-alt-fill"></Icon>
                        <span>Payment Methods</span>
                      </Link>
                    </li>
                    : '' }
                    {ctx.sts.perms.includes('458') || ctx.sts.perms.includes('9999') ?
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-kyc`}
                        className={
                          window.location.pathname ===
                          `${process.env.PUBLIC_URL}/user-kyc`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="file-text-fill"></Icon>
                        <span>KYC Document</span>
                      </Link>
                    </li> : ''}
                    {ctx.sts.perms.includes('452') || ctx.sts.perms.includes('9999') ?
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile-activity`}
                        className={
                          window.location.pathname ===
                          `${process.env.PUBLIC_URL}/user-profile-activity`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="activity-round-fill"></Icon>
                        <span>Account Activity</span>
                      </Link>
                    </li> : ''}
                    {ctx.sts.perms.includes('454') || ctx.sts.perms.includes('9999') ?
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile-log`}
                        className={
                          window.location.pathname ===
                          `${process.env.PUBLIC_URL}/user-profile-log`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="user-list-fill"></Icon>
                        <span>Profile Logs</span>
                      </Link>
                    </li> : ''}
                    {ctx.sts.perms.includes('456') || ctx.sts.perms.includes('9999') ?
                    <li onClick={() => updateSm(false)}>
                      <Link
                        to={`${process.env.PUBLIC_URL}/user-profile-setting`}
                        className={
                          window.location.pathname ===
                          `${process.env.PUBLIC_URL}/user-profile-setting`
                            ? "active"
                            : ""
                        }
                      >
                        <Icon name="lock-alt-fill"></Icon>
                        <span>Change Password</span>
                      </Link>
                    </li> : ''}
                  </ul>
                  <div className="px-4 pb-2">
                    {delRequested == 6 && (
                      <Button
                        color="primary"
                        size="sm"
                        className={"deleteAccount"}
                        outline={true}
                        onClick={(ev) => {
                          setDelModal(true);
                        }}
                      >
                        <Spinner size={"sm"} className="mr-1" />
                        &nbsp; Loading...
                      </Button>
                    )}
                    {userType == 3 &&
                      (delRequested == 2 || delRequested == 3) && (
                        <Button
                          color="primary"
                          size="sm"
                          outline={true}
                          onClick={(ev) => {
                            ev.target.blur();
                            reActiveAccount();
                          }}
                        >
                          {requestLoader ? (
                            <>
                              <Spinner size={"sm"} className="mr-1" />
                              &nbsp;Loading...
                            </>
                          ) : (
                            <>
                              <Icon name="user-alt"></Icon>&nbsp;Cancel Request
                            </>
                          )}
                        </Button>
                      )}
                    {userType == 3 && ctx.sts.perms.includes('9999') &&
                      (delRequested == 0 || delRequested == 1) && (
                        <Button
                          color="primary"
                          size="sm"
                          className={"deleteAccount"}
                          outline={true}
                          onClick={(ev) => {
                            ev.target.blur();
                            Swal.fire({
                              title: "Are you sure?",
                              text: "You really want to do this action",
                              icon: "warning",
                              showCancelButton: true,
                              allowOutsideClick: false,
                              confirmButtonText: "Yes!",
                              cancelButtonText: "No!",
                              focusCancel: true,
                            }).then(async (result) => {
                              if (result.isConfirmed) {
                                setErrDel({});
                                setTotalChar(300);
                                setDelModal(true);
                              }
                            });
                          }}
                        >
                          <Icon name="trash-fill"></Icon>&nbsp; Delete Account
                        </Button>
                      )}
                    {userType == 2 && ctx.sts.perms.includes('9999') && delRequested == 2 && (
                      <Button
                        color="primary"
                        size="sm"
                        outline={true}
                        onClick={(ev) => {
                          ev.target.blur();
                          reActiveAccount();
                        }}
                      >
                        {requestLoader ? (
                          <>
                            <Spinner size={"sm"} className="mr-1" />
                            &nbsp;Loading...
                          </>
                        ) : (
                          <>
                            <Icon name="user-alt"></Icon>&nbsp;Cancel Request
                          </>
                        )}
                      </Button>
                    )}
                    {userType == 2 && ctx.sts.perms.includes('9999') && delRequested == 0 && (
                      <Button
                        color="primary"
                        size="sm"
                        outline={true}
                        className={"deleteAccount"}
                        onClick={(ev) => {
                          ev.target.blur();
                          Swal.fire({
                            title: "Are you sure?",
                            text: "You really want to do this action",
                            icon: "warning",
                            showCancelButton: true,
                            allowOutsideClick: false,
                            confirmButtonText: "Yes!",
                            cancelButtonText: "No!",
                            focusCancel: true,
                          }).then(async (result) => {
                            if (result.isConfirmed) {
                              setErrDel({});
                              setTotalChar(300);
                              setDelModal(true);
                            }
                          });
                        }}
                      >
                        <Icon name="trash-fill"></Icon>&nbsp; Delete Account
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="card-inner boxscroll">
              {sm && mobileView && (
                <div
                  className="toggle-overlay"
                  onClick={() => updateSm(!sm)}
                ></div>
              )}
              <Switch>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/profile`}
                  render={() => (
                    <UserProfileRegularPage
                      updateSm={updateSm}
                      setModal={setModal}
                      sm={sm}
                      userInfo={formData}
                    />
                  )}
                ></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile-notification`}
                  render={() => (
                    <UserProfileNotificationPage updateSm={updateSm} sm={sm} />
                  )}
                ></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile-activity`}
                  render={() => (
                    <UserProfileActivityPage updateSm={updateSm} sm={sm} />
                  )}
                ></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-payment-method`}
                  render={() => <UserPayment updateSm={updateSm} sm={sm} />}
                ></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-kyc`}
                  render={() => <UserKYC updateSm={updateSm} sm={sm} />}
                ></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile-log`}
                  render={() => <UserProfilesLog updateSm={updateSm} sm={sm} />}
                ></Route>
                <Route
                  exact
                  path={`${process.env.PUBLIC_URL}/user-profile-setting`}
                  render={() => (
                    <UserProfileSettingPage updateSm={updateSm} sm={sm} />
                  )}
                ></Route>
              </Switch>
            </div>
          </div>
        </Card>

        <ToastContainer />
      </Content>
      <Modal
        isOpen={modal}
        className="modal-dialog-centered"
        size="lg"
        toggle={() => setModal(false)}
        backdrop="static"
      >
        <ModalBody>
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setModal(false);
              setUserData();
              setValid("");
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Update Profile</h5>
            <ul className="nk-nav nav nav-tabs mb-4">
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "1" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("1");
                  }}
                  href="#personal"
                >
                  Personal
                </a>
              </li>
              {/* <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "2" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("2");
                  }}
                  href="#address"
                >
                  Address
                </a>
              </li> */}
            </ul>

            <Form onSubmit={handleSubmit(submitForm)}>
              <div className="tab-content">
                <div
                  className={`tab-pane ${modalTab === "1" ? "active" : ""}`}
                  id="personal"
                >
                  <Row className="gy-4">
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label" htmlFor="full-name">
                          First Name
                        </label>
                        <input
                          type="text"
                          id="full-name"
                          className="form-control"
                          name={"first_name"}
                          maxLength={30}
                          disabled={
                            kycdata.photo_verified == 2 ||
                            kycdata.photo_id_verified == 2
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            const value = e.target.value.trim();
                            const isValid = /^[A-Za-z\s]+$/.test(value); // Check if value contains only letters and spaces
                            const isEmptyOrSpaces =
                              value.length === 0 || /^\s+$/.test(value); // Check if value is empty or contains only spaces

                            if (isEmptyOrSpaces) {
                              // If the value is empty or only contains spaces, set the validation error message
                              setValid({
                                ...valid,
                                first_name: "Please enter first name",
                              });
                            } else if (!isValid) {
                              // If the value contains special characters, set the validation error message
                              setValid({
                                ...valid,
                                first_name:
                                  "Please do not use special characters.",
                              });
                            } else {
                              // If the value is valid, clear the validation error message
                              setValid({ ...valid, first_name: "" });
                            }

                            // Optionally update the form data state if needed
                            // setFormData({ ...formData, first_name: value });
                          }}
                          defaultValue={formData.first_name}
                          placeholder="Enter First Name"
                          ref={register({
                            required: "Please enter first name.",
                            pattern: {
                              value: /^[a-zA-Z \.]+(?:[ ]?[a-zA-Z\.]+)*$/,
                              message: "Please do not use special characters",
                            },
                            validate: (value) => {
                              const trimmedValue = value.trim();
                              if (trimmedValue.length === 0) {
                                return "Please enter first name.";
                              }
                              return true;
                            },
                          })}
                        />
                        {getErrorServerSite.first_name ? (
                          <span className="sm-error">
                            {getErrorServerSite.first_name}
                          </span>
                        ) : (
                          valid.first_name && (
                            <span className="sm-error">
                              {" "}
                              {valid.first_name}{" "}
                            </span>
                          )
                        )}
                        <input
                          type="hidden"
                          name={"profile_lock"}
                          defaultValue={0}
                          ref={register}
                        />
                        <input
                          type="hidden"
                          name={"kyc_lock"}
                          defaultValue={
                            kycdata.photo_verified == 2 ||
                            kycdata.photo_id_verified == 2
                              ? true
                              : false
                          }
                          ref={register}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label" htmlFor="display-name">
                          Last Name
                        </label>
                        <input
                          type="text"
                          id="last_name"
                          className="form-control"
                          name="last_name"
                          maxLength={30}
                          disabled={
                            kycdata.photo_verified == 2 ||
                            kycdata.photo_id_verified == 2
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            const value = e.target.value.trim();
                            const isValid = /^[A-Za-z\s]+$/.test(value); // Check if value contains only letters and spaces
                            const isEmptyOrSpaces =
                              value.length === 0 || /^\s+$/.test(value); // Check if value is empty or only spaces

                            if (isEmptyOrSpaces) {
                              // If the value is empty or contains only spaces, set the validation error message
                              setValid({
                                ...valid,
                                last_name: "Please enter last name",
                              });
                            } else if (!isValid) {
                              // If the value contains special characters, set the validation error message
                              setValid({
                                ...valid,
                                last_name:
                                  "Please do not use special characters.",
                              });
                            } else {
                              // If the value is valid, clear the validation error message
                              setValid({ ...valid, last_name: "" });
                            }
                          }}
                          defaultValue={formData.last_name}
                          placeholder="Enter Last Name"
                          ref={register({
                            required: "Please enter last name.",
                            pattern: {
                              value: /^[a-zA-Z \.]+(?:[ ]?[a-zA-Z\.]+)*$/,
                              message: "Please do not use special characters",
                            },
                            validate: (value) => {
                              const trimmedValue = value.trim();
                              if (trimmedValue.length === 0) {
                                return "Please enter last name.";
                              }
                              return true;
                            },
                          })}
                        />
                        {getErrorServerSite.last_name ? (
                          <span className="sm-error">
                            {getErrorServerSite.last_name}
                          </span>
                        ) : (
                          valid.last_name && (
                            <span className="sm-error">
                              {" "}
                              {valid.last_name}{" "}
                            </span>
                          )
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label" htmlFor="address-st">
                          Country
                        </label>
                        {kycdata.photo_verified == 2 ||
                        kycdata.photo_id_verified == 2 ? (
                          <input
                            type="text"
                            id="address-st"
                            defaultValue={formData.country}
                            name="country"
                            ref={register}
                            disabled={true}
                            className="form-control"
                          />
                        ) : (
                          <select
                            className="form-control"
                            name="country"
                            ref={register({
                              required: "Please enter your country name.",
                            })}
                            onChange={(e) => handleSelectChange(e)}
                          >
                            {countries != null
                              ? countries.map((row, key) => {
                                  return (
                                    <option
                                      key={key}
                                      phonecode={row.phonecode}
                                      selected={
                                        formData &&
                                        row.label == formData.country
                                          ? true
                                          : false
                                      }
                                    >
                                      {row.label}
                                    </option>
                                  );
                                })
                              : ""}
                          </select>
                        )}
                        {errors.country && (
                          <span className="sm-error">
                            {errors.country.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label" htmlFor="phone-no">
                          Phone Number
                        </label>
                        {/* <div className="row"> */}
                        {/* <div className="col-md-12"> */}
                        <div className="form-control-wrap">
                          <div className="input-group input-group-md">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text"
                                id="inputGroup-sizing-md"
                              >
                                {phnCode ? phnCode : formData.phonecode}
                              </span>
                            </div>
                            <input
                              type="text"
                              id="phone-no"
                              className="form-control"
                              name="phone"
                              defaultValue={formData.phone}
                              placeholder="Phone Number"
                              maxLength={15}
                              onChange={(e) => {
                                const value = e.target.value.trim();
                                const isEmptyOrSpaces =
                                  value.length === 0 || /^\s+$/.test(value); // Check if value is empty or contains only spaces

                                if (isEmptyOrSpaces) {
                                  setError("phone", {
                                    type: "manual",
                                    message: "Please enter phone number.",
                                  });
                                } else if (!/^\d+$/.test(value)) {
                                  setError("phone", {
                                    type: "manual",
                                    message:
                                      "Phone Number should be numeric only.",
                                  });
                                } else if (value.length < 4) {
                                  setError("phone", {
                                    type: "manual",
                                    message:
                                      "Phone Number minimum length should be 4 digits.",
                                  });
                                } else if (value.length > 15) {
                                  setError("phone", {
                                    type: "manual",
                                    message:
                                      "Phone Number maximum length should be 15 digits.",
                                  });
                                } else {
                                  clearErrors("phone");
                                }
                              }}
                              ref={register({
                                required: "Please enter phone number.",
                                validate: (value) => {
                                  const trimmedValue = value.trim();

                                  // Check if the input is empty
                                  if (trimmedValue.length === 0) {
                                    return "Please enter phone number.";
                                  }

                                  // Check if the input matches the numeric pattern
                                  const numericPattern = /^\d+$/;
                                  if (!numericPattern.test(trimmedValue)) {
                                    return "Phone Number should be numeric only.";
                                  }

                                  // Check for minLength
                                  if (trimmedValue.length < 4) {
                                    return "Phone Number minimum length should be 4 digits.";
                                  }

                                  // Check for maxLength
                                  if (trimmedValue.length > 15) {
                                    return "Phone Number maximum length should be 15 digits.";
                                  }

                                  return true; // All validations passed
                                },
                              })}
                            />
                          </div>
                        </div>
                        {errors.phone && (
                          <span className="sm-error">
                            {errors.phone.message}
                          </span>
                        )}
                        {valid.phone && (
                          <span className="sm-error">{valid.phone}</span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label" htmlFor="messenger">
                          Messenger
                        </label>
                        <select
                          className="form-control"
                          id="messenger"
                          name="messenger_type"
                          ref={register({
                            required: "Please select your messenger.",
                          })}
                          onChange={(e) => {
                            if (errors.messenger_name) {
                              clearErrors("messenger_name");
                            }
                            if (e.target.value == "None") {
                              setNickDisabled(true);
                            } else {
                              setValue("messenger_name", "");
                              setNickDisabled(false);
                            }
                          }}
                        >
                          {/* <option
                            selected={formData.messenger_type == "" && true}
                            defaultValuevalue=""
                          >
                            Select Messenger
                          </option> */}
                          {messengers.length > 0 &&
                            messengers.map((option, index) => (
                              <option
                                selected={
                                  formData.messenger_type == option.id && true
                                }
                                defaultValue={option.value}
                              >
                                {option.value}
                              </option>
                            ))}
                        </select>
                        {errors.messenger_type && (
                          <span className="sm-error">
                            {errors.messenger_type.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    {!nickDisabled && (
                      <Col md="6">
                        {/* <FormGroup>
                          <label className="form-label" htmlFor="nick-name">
                          ID/Number
                          </label>
                          <input
                            type="text"
                            id="nick-name"
                            className="form-control"
                            name={"messenger_name"}
                            maxLength={32}
                            disabled={nickDisabled}
                            onChange={(e) => {
                              if (getValues("messenger_type") == "Skype") {
                                const skypeIdRegex =
                                  /^live:.cid.[a-z0-9]{14,22}$/;
                                if (!skypeIdRegex.test(e.target.value)) {
                                  setError("messenger_name", {
                                    type: "manual",
                                    message: "Skype Name is Invalid",
                                  });
                                  return;
                                }
                              } else if (/^\s*$/.test(e.target.value)) {
                                setValue(
                                  "messenger_name",
                                  e.target.value.trim(),
                                  { shouldValidate: true }
                                );
                              }
                            }}
                            defaultValue={formData.messenger_name}
                            placeholder="Enter ID/Number"
                            ref={register({
                              required:
                                "Please enter your ID/Number.",
                            })}
                          />
                          {errors.messenger_name && (
                            <span className="sm-error">
                              {errors.messenger_name.message}
                            </span>
                          )}
                        </FormGroup> */}
                        <FormGroup>
                          <label className="form-label" htmlFor="nick-name">
                            ID/Number
                          </label>
                          <input
                            type="text"
                            id="nick-name"
                            className="form-control"
                            name={"messenger_name"}
                            maxLength={32}
                            disabled={nickDisabled}
                            onChange={(e) => {
                              if (getValues("messenger_type") == "Skype") {
                                const skypeIdRegex =
                                  /^live:.cid.[a-z0-9]{14,22}$/;
                                if (!skypeIdRegex.test(e.target.value)) {
                                  setError("messenger_name", {
                                    type: "manual",
                                    message: "Skype Name is Invalid",
                                  });
                                  return;
                                }
                              } else if (/^\s*$/.test(e.target.value)) {
                                setValue(
                                  "messenger_name",
                                  e.target.value.trim(),
                                  { shouldValidate: true }
                                );
                              } else if (
                                /<\/?[\w\s="/.':;#-\/]+>/gi.test(e.target.value)
                              ) {
                                setError("messenger_name", {
                                  type: "manual",
                                  message: "Please enter valid ID/Number.",
                                });
                              } else {
                                setValue("messenger_name", e.target.value, {
                                  shouldValidate: true,
                                });
                              }
                            }}
                            defaultValue={formData.messenger_name}
                            placeholder="Enter ID/Number"
                            ref={register({
                              required: "Please enter your ID/Number.",
                              pattern: {
                                value: /^[^<>]*$/,
                                message: "Please enter valid ID/Number.",
                              },
                            })}
                          />
                          {errors.messenger_name && (
                            <span className="sm-error">
                              {errors.messenger_name.message}
                            </span>
                          )}
                        </FormGroup>
                      </Col>
                    )}
                    <Col md="6">
                      <FormGroup>
                        <label
                          className="form-label word-wrap break-word"
                          htmlFor="address-l1"
                        >
                          Address Line 1
                        </label>
                        <input
                          type="text"
                          id="address1"
                          name="address_line1"
                          maxLength={60}
                          disabled={
                            kycdata.photo_verified == 2 ||
                            kycdata.photo_id_verified == 2
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            if (/^\s*$/.test(e.target.value)) {
                              setValue("address_line1", e.target.value.trim(), {
                                shouldValidate: true,
                              });
                            }
                            if (e.target.value.trim().length > 0) {
                              clearErrors("address_line1");
                            }
                          }}
                          defaultValue={formData.address_line1}
                          className="form-control"
                          ref={register({
                            required: "Please enter your address.",
                          })}
                        />
                        {errors.address_line1 && (
                          <span className="sm-error">
                            {errors.address_line1.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label" htmlFor="address-l2">
                          Address Line 2
                        </label>
                        <input
                          type="text"
                          id="address-l2"
                          name="address_line2"
                          disabled={
                            kycdata.photo_verified == 2 ||
                            kycdata.photo_id_verified == 2
                              ? true
                              : false
                          }
                          maxLength={60}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              address_line2: e.target.value,
                            });
                          }}
                          defaultValue={formData.address_line2}
                          className="form-control"
                          ref={register}
                        />
                        {errors.address_line2 && (
                          <span className="sm-error">
                            {errors.address_line2.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label" htmlFor="address-st">
                          City
                        </label>
                        <input
                          type="text"
                          id="address-st"
                          name="city"
                          maxLength={50}
                          disabled={
                            kycdata.photo_verified == 2 ||
                            kycdata.photo_id_verified == 2
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value.trim() === "") {
                              setValue("city", value, {
                                shouldValidate: false,
                              });
                              setError("city", {
                                type: "manual",
                                message: "Please enter your city.",
                              });
                            } else if (/^[A-Za-z\s]+$/.test(value)) {
                              setValue("city", value, { shouldValidate: true });
                              clearErrors("city");
                            } else {
                              setError("city", {
                                type: "manual",
                                message:
                                  "Please do not use special characters.",
                              });
                            }
                          }}
                          defaultValue={formData.city}
                          className="form-control"
                          ref={register({
                            required: "Please enter your city.",
                            validate: {
                              nonEmpty: (value) => {
                                return (
                                  value.trim().length > 0 ||
                                  "Please enter your city."
                                );
                              },
                              noSpecialChars: (value) => {
                                return (
                                  /^[A-Za-z\s]+$/.test(value) ||
                                  "Please do not use special characters."
                                );
                              },
                            },
                          })}
                        />
                        {errors.city && (
                          <span className="sm-error">
                            {errors.city.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label" htmlFor="address-st">
                          State
                        </label>
                        <input
                          type="text"
                          id="address-st"
                          name="state"
                          maxLength={50}
                          disabled={
                            kycdata.photo_verified == 2 ||
                            kycdata.photo_id_verified == 2
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value.trim() === "") {
                              setValue("state", value, {
                                shouldValidate: false,
                              });
                              setError("state", {
                                type: "manual",
                                message: "Please enter your state name.",
                              });
                            } else if (/^[A-Za-z\s]+$/.test(value)) {
                              setValue("state", value, {
                                shouldValidate: true,
                              });
                              clearErrors("state");
                            } else {
                              setError("state", {
                                type: "manual",
                                message:
                                  "Please do not use special characters.",
                              });
                            }
                          }}
                          defaultValue={formData.state}
                          className="form-control"
                          ref={register({
                            required: "Please enter your state name.",
                            validate: {
                              nonEmpty: (value) => {
                                return (
                                  value.trim().length > 0 ||
                                  "Please enter your state name."
                                );
                              },
                              noSpecialChars: (value) => {
                                return (
                                  /^[A-Za-z\s]+$/.test(value) ||
                                  "Please do not use special characters."
                                );
                              },
                            },
                          })}
                        />
                        {errors.state && (
                          <span className="sm-error">
                            {errors.state.message}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col size="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        { ctx.sts.perms.includes('448') || ctx.sts.perms.includes('9999') ? 
                        <li>
                          <Button
                            color="primary"
                            size="lg"
                            onClick={(ev) => {
                              // setLoading(true);
                              // ev.preventDefault();
                              // submitForm();
                            }}
                          >
                            Update Profile
                          </Button>
                          {/* <Button
                            color="primary"
                            size="lg"
                            onClick={(ev) => {
                              // ev.preventDefault();
                              // submitForm();
                            }}
                          >
                           Update Profile
                          </Button> */}
                        </li> : ''}
                        <li>
                          <a
                            href="#dropdownitem"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setUserData();
                              setModal(false);
                              setValid("");
                            }}
                            className="btn btn-default btn-lg"
                          >
                            Cancel
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </div>
              </div>
            </Form>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={delModal}
        className="modal-dialog-centered"
        size="lg"
        toggle={() => setDelModal(false)}
      >
        <ModalHeader className="sm-title">
          Request for remove account
          <a
            href="#cancel"
            onClick={(ev) => {
              ev.preventDefault();
              setDelModal(false);
            }}
            className="close"
          >
            {" "}
            <Icon name="cross-sm"></Icon>{" "}
          </a>
        </ModalHeader>
        <ModalBody>
          {delRequested == 2 || delRequested == 3 ? (
            <Col md={"12"}>
              <h4 className="hdcolor py-3 text-center">
                Your Request has been delivered!
              </h4>
              <p className="text-justify mb-4 text-center">
                We are sad to see you go. Your account deletion request has been
                successfully registered. Your account is scheduled for deletion
                within the next 90 days. During this period, our team will be in
                touch with you to assist with any concerns you may have. We are
                here to make the process as smooth as possible for you.
              </p>
            </Col>
          ) : (
            <Form onSubmit={deleteProfileFormSubmit}>
              <Col md={"12"}>
                <FormGroup>
                  <label className="form-label" htmlFor="full-name">
                    Message
                  </label>
                  <textarea
                    className="form-control form-control-sm no-resize"
                    id="fv-message"
                    name="message"
                    value={formData.message}
                    maxLength={300}
                    onChange={handleDeleteProfileFormChange}
                    placeholder="Write your message"
                  />
                  {errDel.message && (
                    <span className="sm-error">{errDel.message}</span>
                  )}
                  <div className="text-muted">
                    Remaining Characters : {totalChar}
                  </div>
                </FormGroup>
                <Button color={"primary"} size={"lg"}>
                  {requestLoader ? (
                    <>
                      <Spinner size={"sm"} className="mr-1" />
                      &nbsp;Loading...
                    </>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Col>
            </Form>
          )}
        </ModalBody>
      </Modal>
      <Loader visible={loading} />
    </React.Fragment>
  );
};

export default UserProfileLayout;
