import React, { useEffect } from "react";
import { useState } from "react";
import Head from "../../layout/head/Head";
import Content from "../../layout/content/Content";
import Loader from "../../app/Loader";
import * as moment from "moment";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  DataTableBody,
  DataTableHead,
  DataTableItem,
  DataTableRow,
  Icon,
  PaginationComponent,
} from "../../components/Component";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { profileLogs } from "../../app/api";

const UserProfileLog = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [campData, setCampData] = useState([]);
  const [pgs, setPgs] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(10);

  const getProfileLogs = async (pg = 1, lim = 0) => {
    setLoading(true);
    let itemLim = lim > 0 ? lim : itemPerPage;
    let type = "publisher";
    const res = await profileLogs(pg, itemLim, type);
    console.log(res);
    if (res.row > 0) {
      setData(res.data);
      setCampData(res.camp_data);
      setPgs(res.row);
    } else {
      setData("");
      setCampData("");
      setPgs("");
    }
    setLoading(false);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (currentPage !== pageNumber) {
      getProfileLogs(pageNumber);
    }
  };

  useEffect(() => {
    getProfileLogs();
  }, []);

  return (
    <React.Fragment>
      <Head title="Profile Logs"></Head>
      <Loader visible={loading} />
      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Profile Logs</BlockTitle>
            <BlockDes>You have total {pgs ? pgs : 0} Logs</BlockDes>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>
      <Block>
        <DataTableBody size="md">
          <DataTableHead>
            <DataTableRow className="add1c">
              <span>Date Time</span>
            </DataTableRow>
            <DataTableRow className="">
              <span>User Type</span>
            </DataTableRow>
            <DataTableRow className="">
              <span>Previous</span>
            </DataTableRow>
            <DataTableRow className="">
              <span>Updated</span>
            </DataTableRow>
            <DataTableRow className="add2c">
              <span>Description</span>
            </DataTableRow>
          </DataTableHead>
          {data
            ? data.map((item, key) => {
                return (
                  <>
                    <DataTableItem key={item.uid}>
                      <DataTableRow>
                        <span>{item.createdat}</span>
                      </DataTableRow>
                      <DataTableRow>
                        {item.user_type == 1 && (
                          <span className={`badge badge-dim badge-dark`}>
                            <span>Advertiser</span>
                          </span>
                        )}
                        {item.user_type == 2 ? (
                          <span className={`badge badge-dim badge-success`}>
                            <span>Publisher</span>
                          </span>
                        ) : (
                          item.user_type == 3 && (
                            <span className={`badge badge-dim badge-success`}>
                              <span>Publisher</span>
                            </span>
                          )
                        )}
                      </DataTableRow>
                      <DataTableRow>
                        <span>
                          {item.profile_data[0] && (
                            <span>
                              {item.profile_data[0].profile_created && (
                                <span>
                                  <b>Profile Created</b>
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].first_name && (
                                <span className="wrd-break">
                                  <b>First Name: </b>
                                  {item.profile_data[0].first_name.previous}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].last_name && (
                                <span className="wrd-break">
                                  <b>Last Name: </b>
                                  {item.profile_data[0].last_name.previous}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].phone && (
                                <span className="wrd-break">
                                  <b>Phone Number: </b>
                                  {item.profile_data[0].phone.previous}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].phonecode && (
                                <span className="wrd-break">
                                  <b>Phone Code: </b>+
                                  {item.profile_data[0].phonecode.previous}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].city && (
                                <span className="wrd-break">
                                  <b>City: </b>
                                  {item.profile_data[0].city.previous}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].state && (
                                <span className="wrd-break">
                                  <b>State: </b>
                                  {item.profile_data[0].state.previous}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].country && (
                                <span className="wrd-break">
                                  <b>Country: </b>
                                  {item.profile_data[0].country.previous}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].messenger_name && (
                                <span className="wrd-break">
                                  <b>Messenger Name: </b>
                                  {item.profile_data[0].messenger_name.previous}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].messenger_type && (
                                <span className="wrd-break">
                                  <b>Messenger Type: </b>
                                  {item.profile_data[0].messenger_type.previous}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].address_line1 && (
                                <span className="wrd-break">
                                  <b>Address Line 1: </b>
                                  {item.profile_data[0].address_line1.previous}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].address_line2 && (
                                <span className="wrd-break">
                                  <b>Address Line 2: </b>
                                  {item.profile_data[0].address_line2.previous}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].switch_remark && (
                                <span className="wrd-break">
                                  {item.profile_data[0].switch_remark.previous}
                                  <br />
                                </span>
                              )}
                            </span>
                          )}
                        </span>
                      </DataTableRow>
                      <DataTableRow>
                        <span>
                          {item.profile_data[0] && (
                            <span>
                              {item.profile_data[0].profile_created && (
                                <span>
                                  <b>Profile Created</b>
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].first_name && (
                                <span className="wrd-break">
                                  <b>First Name: </b>
                                  {item.profile_data[0].first_name.updated}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].last_name && (
                                <span className="wrd-break">
                                  <b>Last Name: </b>
                                  {item.profile_data[0].last_name.updated}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].phone && (
                                <span className="wrd-break">
                                  <b>Phone Number: </b>
                                  {item.profile_data[0].phone.updated}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].phonecode && (
                                <span className="wrd-break">
                                  <b>Phone Code: </b>+
                                  {item.profile_data[0].phonecode.updated}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].city && (
                                <span className="wrd-break">
                                  <b>City: </b>
                                  {item.profile_data[0].city.updated}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].state && (
                                <span className="wrd-break">
                                  <b>State: </b>
                                  {item.profile_data[0].state.updated}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].country && (
                                <span className="wrd-break">
                                  <b>Country: </b>
                                  {item.profile_data[0].country.updated}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].messenger_name && (
                                <span className="wrd-break">
                                  <b>Messenger Name: </b>
                                  {item.profile_data[0].messenger_name.updated}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].messenger_type && (
                                <span className="wrd-break">
                                  <b>Messenger Type: </b>
                                  {item.profile_data[0].messenger_type.updated}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].address_line1 && (
                                <span className="wrd-break">
                                  <b>Address 1: </b>
                                  {item.profile_data[0].address_line1.updated}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].address_line2 && (
                                <span className="wrd-break">
                                  <b>Address 2: </b>
                                  {item.profile_data[0].address_line2.updated}
                                  <br />
                                </span>
                              )}

                              {item.profile_data[0].switch_remark && (
                                <span className="wrd-break">
                                  {item.profile_data[0].switch_remark.updated}
                                  <br />
                                </span>
                              )}
                            </span>
                          )}
                        </span>
                      </DataTableRow>
                      <DataTableRow>
                        <span>
                          {item.profile_data[0] && (
                            <span>
                              {item.profile_data[0].message && (
                                <span className="wrd-break">
                                  {item.profile_data[0].message}
                                  <br />
                                </span>
                              )}
                            </span>
                          )}
                        </span>
                      </DataTableRow>
                    </DataTableItem>
                  </>
                );
              })
            : null}
        </DataTableBody>
        <div className="card-inner" style={{ display: "flex" }}>
          <div style={{ alignSelf: "self-start", width: "97%" }}>
            {data ? (
              <PaginationComponent
                itemPerPage={itemPerPage}
                totalItems={pgs}
                paginate={paginate}
                currentPage={currentPage}
              />
            ) : (
              <div className="text-center">
                <span className="text-silent">No Logs found</span>
              </div>
            )}
          </div>
          <div style={{ alignSelf: "end" }}>
            <UncontrolledDropdown>
              <DropdownToggle
                tag="a"
                className="dropdown-toggle bg-white btn btn-sm btn-outline-light btn-icon"
              >
                &nbsp; &nbsp; {itemPerPage} <Icon name="downward-ios"></Icon>
              </DropdownToggle>
              <DropdownMenu right>
                <ul className="link-list-opt no-bdr">
                  <li onClick={() => {}}>
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setItemPerPage(10);
                        getProfileLogs(1, 10);
                      }}
                    >
                      <span>10</span>
                    </DropdownItem>
                  </li>
                  <li onClick={() => {}}>
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setItemPerPage(20);
                        getProfileLogs(1, 20);
                      }}
                    >
                      <span>20</span>
                    </DropdownItem>
                  </li>
                  <li onClick={() => {}}>
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setItemPerPage(50);
                        getProfileLogs(1, 50);
                      }}
                    >
                      <span>50</span>
                    </DropdownItem>
                  </li>
                  <li onClick={() => {}}>
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setItemPerPage(100);
                        getProfileLogs(1, 100);
                      }}
                    >
                      <span>100</span>
                    </DropdownItem>
                  </li>
                  <li onClick={() => {}}>
                    <DropdownItem
                      tag="a"
                      href="#"
                      onClick={(ev) => {
                        ev.preventDefault();
                        setItemPerPage(500);
                        getProfileLogs(1, 500);
                      }}
                    >
                      <span>500</span>
                    </DropdownItem>
                  </li>
                </ul>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </Block>
    </React.Fragment>
  );
};

export default UserProfileLog;
